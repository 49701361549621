<!--MAIN-BANNER-->
  <div id="mainBanner" class="jumbotron mb-0 pb-md-0 pt-md-5">
  <div class="d-flex justify-content-center align-items-start position-relative">
    <div id="download-apps-top" class="d-none d-md-flex flex-column" style="z-index: 1">
      <h1 class="h1 display-1 font-weight-bold Poppins text-white">Envía Recargas a Cuba al Instante y Sin Problemas</h1>
      <span class="h4 font-weight-normal">"Para tu gente, las mejores ofertas de Recarga a Cuba"</span>
      <img width="271" height="65" class="stu-img-stars pt-4 lazyload"
        data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/stars.svg"
        alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
      <div class="d-flex justify-content-start stu-img-stores pt-5 mt-1">
        <span>
          <picture class="d-none d-lg-flex">
            <source data-srcset="assets/home/rating.webp" type="image/webp">
            <source data-srcset="assets/home/rating.png" type="image/png">
            <img width="116" height="116" class="lazyload" data-src="assets/home/rating.png"
                 alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
          </picture>
          <picture class="d-none d-md-flex d-lg-none">
            <source data-srcset="assets/home/rating.webp" type="image/webp">
            <source data-srcset="assets/home/rating.png" type="image/png">
            <img width="90" height="90" class="lazyload" data-src="assets/home/rating.png"
                 alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
          </picture>
          <!--<img class="d-none d-lg-flex lazyload" alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba" width="115" data-src="assets/home/rating.svg">
          <img class="d-none d-md-flex d-lg-none lazyload" alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba" width="90" data-src="assets/home/rating.svg">-->
        </span>
        <a class="px-3"
          href="https://play.google.com/store/apps/details?id=com.cubatel.android&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          aria-label="Google Play" rel="noreferrer" target="_blank"><img width="116" height="116"
            class="lazyload d-none d-lg-flex" data-src="assets/home/google-play.svg"
            alt="Google Play">
          <img width="90" height="90" class="lazyload d-none d-md-flex d-lg-none"
            data-src="assets/home/google-play.svg" alt="Google Play"></a>
        <a href="https://itunes.apple.com/us/app/cubatel/id1300396049" rel="noreferrer" target="_blank"
          aria-label="App Store"><img width="116" height="116" class="lazyload d-none d-lg-flex"
           data-src="assets/home/app-store.svg" alt="App Store">
          <img width="90" height="90" class="lazyload d-none d-md-flex d-lg-none"
            data-src="assets/home/app-store.svg" alt="App Store"></a>
      </div>

    </div>
    <div class="align-self-end d-none d-lg-flex" style="z-index: 1">
      <picture class="d-none d-lg-flex d-xl-none">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/panfilo.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/panfilo.png" type="image/png">
        <img width="260" height="518" class="lazyload" data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/panfilo.png"
          alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
      </picture>
      <picture class="d-none d-xl-flex">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/panfilo.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/panfilo.png" type="image/png">
        <img width="322" height="642" class="lazyload" data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/panfilo.png"
          alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
      </picture>
    </div>
    <div id="recharges-section" class="d-flex flex-column bg-white position-relative" [class.stu-code]="useStuCode && useStuCode != 'DESCUENTO'" style="box-shadow: 0px -5px 5px 0px rgb(0,0,0,.1);z-index: 1">
      <div class="g-signin2" data-onsuccess="onSignIn"></div>
      <div id="offer-banner-mobile" class="d-block d-md-none mt-0 mx-2 mb-4 mt-3 pt-4 pl-4 position-relative">
        <div class="mb-0 d-flex">
          <div *ngIf="!isPromotion" class="d-flex flex-column">
            <span
                  class="h2 text-white text-left Poppins font-weight-bold mt-1" style="; text-transform: initial">Envía Recargas a Cuba al Instante y Sin Problemas</span>
            <span class="h4 font-weight-normal">"Para tu gente, las mejores ofertas de Recarga a Cuba"</span>
            <img width="160" height="32" class="stu-img-stars pt-2 lazyload"
                 data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/stars.svg"
                 alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
          </div>
          <div *ngIf="isPromotion" class="d-flex flex-column">
            <span class="h2 text-white text-left Poppins font-weight-bold mt-1"
                  [innerHTML]="promoTopHtml"></span>
          <span class="h2 expired-date text-left mt-1"
                [innerHTML]="expiredDate" style="color: #014035; text-transform: initial"></span>
          </div>
          <picture class="d-flex flex-column justify-content-end">
            <source srcset="assets/v2/imgs/home/panfilo-3-312x356.webp" type="image/webp">
            <source srcset="assets/v2/imgs/home/panfilo-3-312x356.png" type="image/png">
            <img class="align-self-end" width="171" height="195" src="assets/v2/imgs/home/panfilo-3-312x356.png"
                 alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
          </picture>
        </div>
        <picture class="position-absolute d-block d-md-none" style="z-index: 0; bottom: 0">
          <source data-srcset="assets/home/background.png" type="image/webp">
          <source data-srcset="assets/home/background.png" type="image/png">
          <img class="lazyload w-100" alt="Eviar dinero a Cuba"
               data-src="assets/home/background.png">
        </picture>
      </div>
      <div class="d-flex justify-content-center align-items-center pt-md-2 pt-xl-4">
        <button (click)="toggle(true)" id="mobile-option" class="btn font-weight-normal Poppins"
          [ngClass]="rechargeIsOpen? 'stu-bg-primary text-white': 'stu-primary-color bg-transparent stu-br-primary'">
          <span class="d-flex align-items-center">
            Celulares
          </span>
        </button>
        <button (click)="toggle(false)" id="nauta-option" class="btn font-weight-normal Poppins"
          [ngClass]="nautaIsOpen? 'stu-bg-primary text-white': 'stu-primary-color bg-transparent stu-br-primary'">
          <span class="d-flex align-items-center">
            Nauta
          </span>
        </button>
      </div>
      <div *ngIf="rechargeIsOpen" id="promo-info" class=" px-md-2 px-xl-4 py-3 mt-4 mx-2 d-none d-md-flex">
        <p *ngIf="!isPromotion" class="Poppins font-weight-normal text-left mb-0">
          Más de UN MILLÓN de clientes envían recargas a Cuba con Cubatel
        </p>
        <p *ngIf="isPreRecharge" class="Poppins font-weight-normal text-left mb-0">
          Promoción de Recarga a Cuba:<br />
          Envía {{promotionAmountFullText}} del {{promoDateText}}
        </p>
        <p *ngIf="isPromotion && !isPreRecharge" class="Poppins font-weight-normal text-left mb-0">
          Promoción de Recarga a Cuba:<br />
          Envía {{promotionAmountFullText}} del {{promoDateText}}
        </p>
      </div>
      <div *ngIf="nautaIsOpen" id="promo-info" class=" px-md-2 px-lg-4 px-xl-5 py-3 mt-4 mx-2 d-none d-md-flex">
        <p class="Poppins font-weight-normal text-left mb-0">
          Más de UN MILLÓN de clientes envían recargas a Cuba con Cubatel
        </p>
      </div>
      <form id="home-form" role="form"
        class="mb-5 mt-4 mb-md-0 mt-md-3 mx-md-1 d-flex flex-column justify-content-center position-relative">
        <div *ngIf="rechargeIsOpen && !nautaIsOpen" class="form-group px-2 ">
          <label class="mb-2 Poppins" for="phone-number">Número de celular</label>
          <div class="position-relative d-flex align-items-center"><input id="phone-number" class="form-control"
              maxlength="8" autocomplete="off" (focusout)="phoneNumberUntoched = true"
              [formControl]="phoneNumberControl" type="text" aria-label="Número de Teléfono" />
            <span id="phone-number-prefix" class="position-absolute">+53</span>
          </div>

          <div
            *ngIf="phoneNumberControl.errors && !phoneNumberControl.pristine && phoneNumberUntoched && rechargeIsOpen">
            <div *ngIf="phoneNumberControl.errors.required" class="text-danger error">
              El número es
              obligatorio
            </div>
            <div *ngIf="phoneNumberControl.errors.invalidNumber" class="text-danger error">
              El número no es válido
            </div>
          </div>
        </div>
        <div *ngIf="!rechargeIsOpen && nautaIsOpen" class="form-group px-2">
          <label class="mb-2 Poppins" for="nauta-user">Usuario nauta</label>
          <input id="nauta-user" class="form-control" placeholder="sofia@nauta.com.cu" [formControl]="nautaAccount"
            (focusout)="nautaAccountUntoched = true;" type="text" aria-label="Usuario nauta" />
          <div *ngIf="nautaAccount.errors && !nautaAccount.pristine && nautaAccountUntoched && nautaIsOpen">
            <div *ngIf="nautaAccount.errors.required" class="text-danger error">
              La cuenta nauta es
              obligatoria
            </div>
            <div *ngIf="nautaAccount.errors.invalidNauta" class="text-danger error">
              La cuenta nauta no es válida
            </div>
          </div>
        </div>
        <div class="form-group mt-md-2 mt-xl-4 px-2">
          <label class="mb-2 Poppins" for="prices">Cantidad a recargar</label>
          <div class="position-relative">
            <select id="prices" class="form-control" [formControl]="selectedOfferControl"
              (focusout)="selectedOfferUntoched = true">
              <option *ngFor="let item of promotions;trackBy: trackByPrice" value="{{item.promotionAmountFullText}}">
                <span *ngIf="isPromotion">{{item.promotionAmountFullText}}</span>
                <span *ngIf="!isPromotion">{{item.amountText}}</span>
              </option>
            </select>
            <div *ngIf="selectedOfferControl.errors && !selectedOfferControl.pristine && selectedOfferUntoched">
              <div *ngIf="selectedOfferControl.errors.required" class="text-danger error">
                Debe seleccionar una oferta
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="useStuCode && useStuCode != 'DESCUENTO'" class="form-group px-2 ">
          <label class="mb-2 Poppins" for="phone-number">Código</label>
          <div class="position-relative d-flex align-items-center">
            <input id="stu-code" class="form-control" disabled
                  value="{{useStuCode}}" type="text" aria-label="Código" />
          </div>
        </div>
        <button
          class="btn btn-primary stu-bg-primary px-4 mt-2 mt-md-2 mt-xl-4 font-weight-normal Poppins recharge-button"
          style="font-size:16px" (click)="sendRecharge()">
          <span *ngIf="(isPromotion && !isPreRecharge) || !isPromotion || nautaIsOpen"
            class="d-flex align-items-center justify-content-center">
            Enviar Recarga
          </span>
          <span *ngIf="isPreRecharge && rechargeIsOpen" class="d-flex align-items-center justify-content-center">
            Enviar Recarga
          </span>
        </button>
      </form>
      <div *ngIf="loadingInit | async" class="backdrop" style="border-radius: 8px">
        <div class="text-center">
          <picture>
            <source srcset="assets/loading.webp" type="image/webp">
            <source srcset="assets/loading.gif" type="image/gif">
            <img style="height: 70px; width:70px" src="assets/imgs/loading.gif" alt="Cargando...">
          </picture>
        </div>
      </div>
    </div>
    <picture class="position-absolute d-none d-md-block" style="z-index: 0">
      <source data-srcset="assets/home/background.png" type="image/webp">
      <source data-srcset="assets/home/background.png" type="image/png">
      <img class="lazyload w-100" alt="Eviar dinero a Cuba"
           data-src="assets/home/background.png">
    </picture>
  </div>

</div>
<!--END-MAIN-BANNER-->

<!-- (NEW) SERVICES SECTION -->
<div id="newServicesSection" class="d-flex flex-column mb-3">
  <h2 class="Poppins font-weight-normal mb-3 mb-md-5">Nuestros servicios</h2>
  <div class="d-flex flex-column align-items-center" style="max-width: 1024px">
    <div class="d-flex justify-content-around my-3">
      <div class="main-services d-flex justify-content-around align-items-center p-md-4 mx-2" style="background: white; filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));">
        <a class="d-flex flex-column align-items-center bg-transparent p-2" style="text-decoration:none"
           [routerLink]="[isAuthenticated() ? '/recargas' : '']" (click)="redirectToRecharges()">
          <img class="d-block d-md-none lazyload" width="28"
               alt="Recargas" data-src="assets/home/service-recharge.svg">
          <img class="d-none d-md-block lazyload" width="34"
               alt="Recargas" data-src="assets/home/service-recharge.svg">
          <span class="text-primary text-center font-weight-bold mt-2 OpenSans">Recargas</span></a>
      </div>
      <div class="main-services d-flex justify-content-around align-items-center p-2 p-md-4 mx-2" style="background: white;
filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));">
        <a class="d-flex flex-column align-items-center bg-transparent" style="text-decoration:none"
           routerLink="/llamadas-a-cuba">
          <img class="d-block d-md-none lazyload" width="28"
               alt="Llamadas" data-src="assets/home/service-call.svg">
          <img class="d-none d-md-block lazyload" width="34"
               alt="Llamadas" data-src="assets/home/service-call.svg">
          <span class="text-primary text-center font-weight-bold mt-2 OpenSans">Llamadas</span></a>
      </div>
      <div class="main-services d-flex justify-content-around align-items-center p-2 p-md-4 mx-2" style="background: white;
filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));">
        <a class="d-flex flex-column align-items-center bg-transparent" style="text-decoration:none"
           routerLink="/market">
          <!--<p class="bg-danger rounded-pill text-white px-2 mb-0 position-absolute d-flex"
             style="right: 10px; top: 10px"><small>Hot</small></p>-->
          <img class="d-block d-md-none lazyload" width="28"
               alt="Market" data-src="assets/home/service-market.svg">
          <img class="d-none d-md-block lazyload" width="34"
               alt="Market" data-src="assets/home/service-market.svg">
          <span class="text-primary text-center font-weight-bold mt-2 OpenSans">Market</span></a>
      </div>
      <div class="main-services d-none d-md-flex justify-content-around align-items-center p-2 p-md-4 mx-2" style="background: white;
filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));">
        <a class="d-flex flex-column align-items-center bg-transparent" style="text-decoration:none"
           routerLink="/envio-dinero-cuba">
          <img class="lazyload" width="38"
               alt="Remesa" data-src="assets/home/service-remittance.svg">
          <span class="text-primary text-center font-weight-bold mt-2 OpenSans">Remesas</span></a>
      </div>
      <div class="main-services d-none d-md-flex justify-content-around align-items-center p-2 p-md-4 mx-2" style="background: white;
filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));">
        <a class="d-flex flex-column align-items-center bg-transparent position-relative" style="text-decoration:none"
           routerLink="/market/envios/amazon-para-cuba">
          <img class="lazyload" width="34"
               alt="Amazon" data-src="assets/home/icon_amazon.svg">
          <span class="text-primary text-center font-weight-bold mt-2 OpenSans">Amazon</span></a>
      </div>
      <div class="main-services d-none d-md-flex justify-content-around align-items-center p-2 p-md-4 mx-2" style="background: white;
filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));">
        <a class="d-flex flex-column align-items-center bg-transparent position-relative" style="text-decoration:none"
           routerLink="/market/envios/shein-para-cuba">
          <p class="bg-danger rounded-pill text-white px-2 mb-0 position-absolute d-flex"
             style="right: -30px; top: -15px"><small>Nuevo</small></p>
          <img class="lazyload" width="34"
               alt="Shein" data-src="assets/market-shein/shein.svg">
          <span class="text-primary text-center font-weight-bold mt-2 OpenSans">Shein</span></a>
      </div>

    </div>
    <div class="d-flex d-md-none justify-content-around my-3">
      <div class="main-services d-flex justify-content-around align-items-center p-2 p-md-4 mx-2" style="background: white;
filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));">
        <a class="d-flex flex-column align-items-center bg-transparent position-relative" style="text-decoration:none"
           routerLink="/market/envios/amazon-para-cuba">
          <img class="lazyload" width="34"
               alt="Amazon" data-src="assets/home/icon_amazon.svg">
          <span class="text-primary text-center font-weight-bold mt-2 OpenSans">Amazon</span></a>
      </div>
      <div class="main-services d-flex justify-content-around align-items-center p-2 p-md-4 mx-2" style="background: white;
filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));">
        <a class="d-flex flex-column align-items-center bg-transparent position-relative" style="text-decoration:none"
           routerLink="/market/envios/shein-para-cuba">
          <p class="bg-danger rounded-pill text-white px-2 mb-0 position-absolute d-flex"
             style="right: -30px; top:  -15px"><small>Nuevo</small></p>
          <img class="lazyload" width="34"
               alt="Shein" data-src="assets/market-shein/shein.svg">
          <span class="text-primary text-center font-weight-bold mt-2 OpenSans">Shein</span></a>
      </div>
      <div class="main-services d-flex justify-content-around align-items-center p-2 p-md-4 mx-2" style="background: white;
filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));">
        <a class="d-flex flex-column align-items-center bg-transparent" style="text-decoration:none"
           routerLink="/envio-dinero-cuba">
          <img class="lazyload" width="34"
               alt="Remesa" data-src="assets/home/service-remittance.svg">
          <span class="text-primary text-center font-weight-bold mt-2 OpenSans">Remesas</span></a>
      </div>
      <!--<div class="main-services d-flex justify-content-around align-items-center p-2 p-md-4 mx-2" style="background: white;
filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));">
        <a class="d-flex flex-column align-items-center bg-transparent" style="text-decoration:none"
           routerLink="/recargas-nauta">
          <img class="lazyload" width="28"
               alt="Nauta" data-src="assets/home/service-nauta.svg">
          <span class="text-primary text-center font-weight-bold mt-2 OpenSans">Nauta</span></a>
      </div>-->


    </div>
    <div class="d-flex justify-content-around my-3">
      <div class="main-services d-flex justify-content-around align-items-center p-2 p-md-4 mx-2" style="background: white;
filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));">
        <a class="d-flex flex-column align-items-center bg-transparent" style="text-decoration:none"
           routerLink="/recargas-nauta">
          <img class="lazyload" width="34"
               alt="Nauta" data-src="assets/home/service-nauta.svg">
          <span class="text-primary text-center font-weight-bold mt-2 OpenSans">Nauta</span></a>
      </div>
      <div class="main-services d-flex justify-content-around align-items-center p-2 p-md-4 mx-2" style="background: white;
filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));">
        <a routerLink="/sms-a-cuba" class="d-flex flex-column align-items-center bg-transparent" style="text-decoration:none"
        >
          <img class="lazyload" width="28"
               alt="SMS" data-src="assets/home/service-sms.svg">
          <span class="text-primary text-center font-weight-bold mt-2 OpenSans">SMS</span></a>
      </div>
      <div class="main-services d-flex justify-content-around align-items-center p-2 p-md-4 mx-2 " style="background: white;
filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));">
        <a class="d-flex flex-column align-items-center bg-transparent" style="text-decoration:none"
           routerLink="market/tienda-online-cuba/productos-envio-cuba" >
          <img class="lazyload" width="38"
               alt="Envíos" data-src="assets/home/service-shipment.svg">
          <span class="text-primary text-center font-weight-bold mt-2 OpenSans">Envíos</span></a>
      </div>
      <div class="main-services d-none d-md-flex justify-content-around align-items-center p-2 p-md-4 mx-2" style="background: white;
filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));">
        <a href="tel:+18332822835" class="d-flex flex-column align-items-center bg-transparent" style="text-decoration:none"
        >
          <img class="d-block d-md-none lazyload" width="28"
               alt="Hoteles" data-src="assets/home/service-hotel.svg">
          <img class="d-none d-md-block lazyload" width="34"
               alt="Hoteles" data-src="assets/home/service-hotel.svg">
          <span class="text-primary text-center font-weight-bold mt-2 OpenSans">Hoteles</span></a>
      </div>
      <div class=" main-services d-none d-md-flex justify-content-around align-items-center p-2 p-md-4 mx-2" style="background: white;
filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));">
        <a href="tel:+18332822835" class="d-flex flex-column align-items-center bg-transparent" style="text-decoration:none"
        >
          <img class="d-block d-md-none lazyload" width="28"
               alt="Autos" data-src="assets/home/service-rent-a-carr.svg">
          <img class="d-none d-md-block lazyload" width="34"
               alt="Autos" data-src="assets/home/service-rent-a-carr.svg">
          <span class="text-primary text-center font-weight-bold mt-2 OpenSans">Autos</span></a>
      </div>
      <div class="main-services d-none d-md-flex justify-content-around align-items-center p-2 p-md-4 mx-2" style="background: white;
filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));">
        <a class="d-flex flex-column align-items-center bg-transparent" style="text-decoration:none"
           href="tel:+18332822835">
          <img class="d-block d-md-none lazyload" width="34"
               alt="Viajes" data-src="assets/home/service-flight.svg">
          <img class="d-none d-md-block lazyload" width="38"
               alt="Viajes" data-src="assets/home/service-flight.svg">
          <span class="text-primary text-center font-weight-bold mt-2 OpenSans">Viajes</span></a>
      </div>
     <!-- <div class="main-services d-none d-md-flex justify-content-around align-items-center p-2 p-md-4 mx-2" style="background: white;
filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));">
        <a class="d-flex flex-column align-items-center bg-transparent" style="text-decoration:none"
           href="tel:+18332822835">
          <img class="d-block d-md-none lazyload" width="34"
               alt="Viajes" data-src="assets/home/service-formalities.svg">
          <img class="d-none d-md-block lazyload" width="30"
               alt="Viajes" data-src="assets/home/service-formalities.svg">
          <span class="text-primary text-center font-weight-bold mt-2 OpenSans">Trámites</span></a>
      </div>-->
    </div>
    <div class="d-flex d-md-none justify-content-around my-3">
      <div class=" main-services d-flex justify-content-around align-items-center p-2 p-md-4 mx-2" style="background: white;
filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));">
        <a href="tel:+18332822835" class="d-flex flex-column align-items-center bg-transparent" style="text-decoration:none"
        >
          <img class="d-block d-md-none lazyload" width="28"
               alt="Autos" data-src="assets/home/service-rent-a-carr.svg">
          <img class="d-none d-md-block lazyload" width="34"
               alt="Autos" data-src="assets/home/service-rent-a-carr.svg">
          <span class="text-primary text-center font-weight-bold mt-2 OpenSans">Autos</span></a>
      </div>
      <div class="main-services d-flex justify-content-around align-items-center p-2 p-md-4 mx-2" style="background: white;
filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));">
        <a class="d-flex flex-column align-items-center bg-transparent" style="text-decoration:none"
           href="tel:+18332822835">
          <img class="lazyload" width="34"
               alt="Viajes" data-src="assets/home/service-flight.svg">
          <span class="text-primary text-center font-weight-bold mt-2 OpenSans">Viajes</span></a>
      </div>
      <div class="main-services d-flex justify-content-around align-items-center p-2 p-md-4 mx-2" style="background: white;
filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));">
        <a class="d-flex flex-column align-items-center bg-transparent" style="text-decoration:none"
           href="tel:+18332822835">
          <img class="lazyload" width="24"
               alt="Trámites" data-src="assets/home/service-formalities.svg">
          <span class="text-primary text-center font-weight-bold mt-2 OpenSans">Trámites</span></a>
      </div>
      <!--<div class="main-services d-flex justify-content-around align-items-center p-2 p-md-4 mx-2 " style="background: white;
filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));">
        <a class="d-flex flex-column align-items-center bg-transparent" style="text-decoration:none"
           href="https://www.cubatel.com/blog">
          <img class="lazyload" width="24"
               alt="Blog" data-src="assets/home/service-blog.svg">
          <span class="text-primary text-center font-weight-bold mt-2 OpenSans">Blog</span></a>
      </div>-->
    </div>

    <!--
    <div class="d-flex justify-content-around my-5">
      <div style="flex:20%" class="d-flex mx-2 mx-md-4 px-md-4 justify-content-center position-relative">
        <a class="d-flex flex-column align-items-center bg-transparent" style="text-decoration:none"
           routerLink="/market">
          <picture class="position-absolute new-service">
            <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/services-section/icon-new.webp"
                    type="image/webp">
            <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/services-section/icon-new.png" type="image/png">
            <img class="lazyload" width="41" alt="Nuevo"
                 data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/services-section/icon-new.png">
          </picture>
          <img id="icon-market" class="lazyload d-none d-lg-block" width="72" height="70"
                                     alt="Llamadas" data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/services-section/icon-market.svg">
          <img id="icon-call" class="lazyload d-block d-lg-none" width="41" height="39" alt="Market"
               data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/services-section/icon-market.svg">
          <span class="text-white text-center font-weight-bold mt-2 OpenSans">Market</span></a>
      </div>
      <div style="flex:20%" class="mx-2 mx-md-4 px-md-4 position-relative">
        <a class="d-flex flex-column align-items-center" style="text-decoration:none" routerLink="/envio-dinero-cuba">
          <img id="icon-market" class="lazyload d-none d-lg-block cursor-pointer" width="65" height="63" alt="Remesas"
               data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/services-section/remesas.svg">
          <img id="icon-market" class="lazyload d-block d-lg-none cursor-pointer" width="41" height="44" alt="Remesas"
               data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/services-section/remesas.svg">
          <span class="text-white text-center font-weight-bold mt-2 OpenSans" style="width:112%">Envío de dinero a
            Cuba</span>
        </a>
      </div>
      <div style="flex:20%"
        class="d-flex flex-column align-items-center mx-3 mx-md-4 px-md-4 width-fixed cursor-pointer">
        <img (click)="callToAction1('recharge')" id="icon-recharge" class="lazyload d-none d-lg-block cursor-pointer"
          width="65" height="63" alt="Recargas"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/services-section/icon-recharge.svg">
        <img (click)="callToAction1('recharge')" id="icon-recharge" class="lazyload d-block d-lg-none cursor-pointer"
          width="41" height="44" alt="Recargas"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/services-section/icon-recharge.svg">
        <span (click)="callToAction1('recharge')"
          class="text-white text-center font-weight-bold mt-1 OpenSans cursor-pointer">Recargas para Cuba</span>
      </div>
      <div style="flex:20%"
        class="d-none d-lg-flex flex-column align-items-center mx-3 mx-md-4 px-md-4 width-fixed cursor-pointer">
        <img (click)="callToAction1('nauta')" id="icon-call" class="d-none d-lg-block lazyload cursor-pointer"
          width="72" height="70" alt="Nauta"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/services-section/icon-nauta.svg">
        <img (click)="callToAction1('nauta')" id="icon-call" class="d-block d-lg-none lazyload cursor-pointer"
          width="47" height="34" alt="Llamadas"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/services-section/icon-nauta.svg">
        <span (click)="callToAction1('nauta')"
          class="text-white text-center font-weight-bold mt-2 OpenSans cursor-pointer">Nauta</span>
      </div>
      <div style="flex:20%" class="d-none d-lg-flex mx-4 mx-md-4 px-md-4 width-fixed justify-content-center">
        <a class="d-flex flex-column align-items-center " style="text-decoration:none"
          routerLink="/llamadas-a-cuba"><img id="icon-call" class="lazyload d-none d-lg-block" width="73" height="70"
            alt="Llamadas" data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/services-section/icon-call.svg">
          <img id="icon-call" class="lazyload d-block d-lg-none" width="41" height="39" alt="Llamadas"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/services-section/icon-call.svg">
          <span class="text-white text-center font-weight-bold mt-2 OpenSans">Llamadas</span></a>
      </div>
    </div>
    <div class="d-flex d-lg-none justify-content-around mb-5">
      <div class="d-flex flex-column align-items-center mx-3 mx-md-5 px-md-4 cursor-pointer" style="width:45px">
        <img (click)="callToAction1('nauta')" id="icon-call" class="lazyload cursor-pointer" width="47" height="34"
             alt="Llamadas" data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/services-section/icon-nauta.svg">
        <span (click)="callToAction1('nauta')"
              class="text-white font-weight-bold mt-2 OpenSans cursor-pointer">Nauta</span>
      </div>
      <div class="mx-4 mx-md-4 px-md-4 width-fixed" style="width:50px">
        <a class="d-flex flex-column align-items-center " style="text-decoration:none"
          routerLink="/llamadas-a-cuba"><img id="icon-call" class="lazyload d-none d-lg-block" width="73" height="70"
            alt="Llamadas" data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/services-section/icon-call.svg">
          <img id="icon-call" class="lazyload d-block d-lg-none" width="41" height="39" alt="Llamadas"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/services-section/icon-call.svg">
          <span class="text-white font-weight-bold mt-2 OpenSans">Llamadas</span></a>
      </div>

      <div class="mx-3 mx-md-5 px-md-4 position-relative" style="width:78px">
        <a class="d-flex flex-column align-items-center" style="text-decoration:none" href="tel:+18332822835">
          <img id="icon-recharge" class="lazyload d-airplane d-lg-none" width="41" height="39" alt="Viajes"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/services-section/icon-airplane.svg"><span
            class="text-white font-weight-bold mt-2 OpenSans">Viajes</span>
        </a>
      </div>
    </div>
    <div class="d-flex d-lg-none justify-content-around mb-5">
      <div class="mx-4 mx-md-5 px-md-4 position-relative" style="width:50px">
        <a class="d-flex flex-column align-items-center" style="text-decoration:none" href="tel:+18332822835">
          <img id="icon-call" class="lazyload d-block d-lg-none" width="47" height="39" alt="Envíos"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/services-section/icon-box.svg">
          <span class="text-white font-weight-bold mt-2 OpenSans">Envíos</span>
        </a>
      </div>
      <div class="mx-3 mx-md-5 px-md-4 position-relative" style="width:78px">
        <a class="d-flex flex-column align-items-center" style="text-decoration:none" href="tel:+18332822835">
          <img id="icon-call" class="lazyload d-block d-lg-none" width="49" height="33" alt="Hoteles y Excursiones"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/services-section/icon-hotel.svg">
          <span class="text-white text-center font-weight-bold mt-2 OpenSans">Hoteles y<br> Excursiones</span>
        </a>
      </div>
      <div class="mx-3 mx-md-5 px-md-4 position-relative" style="width:78px">
        <a class="d-flex flex-column align-items-center" style="text-decoration:none" href="tel:+18332822835">
          <img id="icon-call" class="lazyload" width="60" height="38" alt="Renta de Autos"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/services-section/icon-carr.svg">
          <span class="text-white text-center font-weight-bold mt-2 OpenSans">Renta de<br> autos</span>
        </a>
      </div>
    </div>
    <div class="d-flex d-lg-none justify-content-start">
      <div class="mx-3 mx-md-5 px-md-4 pl-4 position-relative" style="width:78px">
        <a class="d-flex flex-column align-items-center" style="text-decoration:none" href="tel:+18332822835">
          <img id="icon-call" class="lazyload" width="49" height="43" alt="Trámites"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/services-section/icon-tramites.svg">
          <span class="text-white font-weight-bold mt-2 OpenSans">Trámites</span>
        </a>
      </div>
    </div>
    <div class="d-none d-lg-flex justify-content-between w-100 my-5">
      <div style="flex:20%" class="mx-2 mx-md-4 px-md-4 position-relative">
        <a class="d-flex flex-column align-items-center" style="text-decoration:none" href="tel:+18332822835">
          <img id="icon-airplane" class="lazyload icon-airplane" width="74" height="70" alt="Vuelos"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/services-section/icon-airplane.svg">
          <span class="text-white text-center font-weight-bold mt-2 OpenSans">Viajes</span>
        </a>
      </div>
      <div style="flex:20%" class="mx-2 mx-md-4 px-md-4 position-relative">
        <a class="d-flex flex-column align-items-center" style="text-decoration:none" href="tel:+18332822835">
          <img id="icon-box" class="lazyload" width="85" height="72" alt="Envíos"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/services-section/icon-box.svg">
          <span class="text-white text-center font-weight-bold mt-2 OpenSans">Envíos</span>
        </a>
      </div>
      <div style="flex:20%" class="mx-2 mx-md-4 px-md-4 position-relative">
        <a class="d-flex flex-column align-items-center" style="text-decoration:none" href="tel:+18332822835">
          <img id="icon-box" class="lazyload" width="72" height="49" alt="Hoteles y Excursiones"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/services-section/icon-hotel.svg">
          <span class="text-center text-white font-weight-bold mt-2 OpenSans">Hoteles y <br>Excursiones</span>
        </a>
      </div>
      <div style="flex:20%" class="mx-2 mx-md-4 px-md-4 position-relative">
        <a class="d-flex flex-column align-items-center" style="text-decoration:none" href="tel:+18332822835">
          <img id="icon-box" class="lazyload" width="88" height="56" alt="Renta de Autos"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/services-section/icon-carr.svg">
          <span class="text-white text-center font-weight-bold mt-2 OpenSans">Renta de <br> Autos</span>
        </a>
      </div>
      <div style="flex:20%" class="mx-2 mx-md-4 px-md-4 position-relative">
        <a class="d-flex flex-column align-items-center" style="text-decoration:none" href="tel:+18332822835">
          <img id="icon-call" class="lazyload" width="88" height="56" alt="Trámites"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/services-section/icon-tramites.svg">
          <span class="text-white text-center font-weight-bold mt-2 OpenSans">Trámites</span>
        </a>
      </div>
    </div>-->
  </div>
</div>
<!-- END SERVICES SECTION -->

<!-- DESKTOP SECTION SHEIN -->
<section class="pt-2">
  <div id="shein-desktop-section" class="mx-md-auto position-relative" style="max-width: 1158px">
    <picture class="position-absolute d-none d-md-block" style="z-index: 0; top: 0">
      <source data-srcset="assets/market/background.png" type="image/webp">
      <source data-srcset="assets/market/background.png" type="image/png">
      <img style="float: right" class="lazyload w-100" alt="Amazon para Cuba"
           data-src="assets/market/background.png">
    </picture>
    <div class="mobile-section-1-1 d-flex flex-column flex-md-row position-relative justify-content-around"
         style="max-width: 1158px;">
      <div class="mobile-section-1-1-container amazon-texts d-flex flex-column align-items-center align-items-md-start pt-0 pt-md-4 pb-4 py-md-4 px-1 mt-md-4"
           style="margin-left: auto">
        <picture class="d-flex d-md-none">
          <source data-srcset="assets/market-shein/shein-img1-mobile.png" type="image/webp">
          <source data-srcset="assets/market-shein/shein-img1-mobile.png" type="image/png">
          <img width="300" height="220" class="lazyload m-auto" alt="Amazon para Cuba"
               data-src="assets/market-shein/shein-img1-mobile.png">
        </picture>
        <div class="d-md-none w-100 d-flex justify-content-center mb-3" style="bottom: 2%">
          <a routerLink="/market/envios/shein-para-cuba"
             class=" btn btn-danger bg-white px-3 py-2 mt-md-2 mt-lg-4 font-weight-bold OpenSans rounded-pill d-flex align-items-center justify-content-center w-75"
             style="font-size: 22px; color: #F3797A">
            Saber Más</a>
        </div>
        <h2 class="Poppins text-center text-md-left text-white"><span class="font-weight-bold" style="font-size: 36px">¡NUEVO SERVICIO!</span><br>
          <span class="amazon-text-2">Compra en Shein<br> y reciben</span><br><span class="amazon-text-1 font-weight-normal">DIRECTAMENTE EN CUBA</span></h2>
        <a routerLink="/market/envios/shein-para-cuba"
           class="d-none d-md-flex btn btn-danger bg-white px-3 py-2 mt-md-2 mt-lg-4 font-weight-bold OpenSans rounded-pill align-items-center justify-content-center mr-auto w-75"
           style="font-size: 22px;color: #F3797A">
          Saber Más</a>
      </div>
      <div class="mobile-section-1-1-img position-relative ml-auto">
        <picture class="d-none d-md-flex">
          <source data-srcset="assets/market-shein/shein-img1.png" type="image/webp">
          <source data-srcset="assets/market-shein/shein-img1.png" type="image/png">
          <img width="400" height="363" class="lazyload m-auto" alt="Amazon para Cuba"
               data-src="assets/market-shein/shein-img1.png">
        </picture>
      </div>
    </div>

  </div>
</section>

<!-- END DESKTOP SECTION SHEIN -->

<!-- DESKTOP SECTION AMAZON -->
<section class="pt-4">
  <div id="amazon-desktop-section" class="mx-md-auto position-relative" style="max-width: 1158px">
    <div class="mobile-section-1-1 d-flex flex-column flex-md-row position-relative justify-content-around pt-md-4"
         style="max-width: 1158px;">
      <div class="mobile-section-1-1-container amazon-texts d-flex flex-column align-items-start p-4 mt-4"
           style="top:5%;left:5%">
        <h2 class="Poppins text-left text-white font-weight-bold"><span class="amazon-text-1 font-weight-normal">¡AHORA PUEDES</span><br>
          <span class="amazon-text-2">ENVIAR<br> PRODUCTOS</span><br><span class="amazon-text-1 font-weight-normal">DE AMAZON A CUBA!</span></h2>
        <a routerLink="/market/envios/amazon-para-cuba"
           class="d-none d-md-flex btn btn-danger text-white px-3 py-2 mt-md-2 mt-lg-4 font-weight-bold OpenSans rounded-pill align-items-center justify-content-center mr-auto w-75"
           style="font-size: 22px;">
          Saber Más</a>
      </div>
      <div class="mobile-section-1-1-img position-relative">
        <picture class="d-flex d-md-none">
          <source data-srcset="assets/market-amazon/panfilo.png" type="image/webp">
          <source data-srcset="assets/market-amazon/panfilo.png" type="image/png">
          <img width="300" height="272" class="lazyload m-auto" alt="Amazon para Cuba"
               data-src="assets/market-amazon/panfilo.png">
        </picture>
        <picture class="d-none d-md-flex">
          <source data-srcset="assets/market-amazon/panfilo.png" type="image/webp">
          <source data-srcset="assets/market-amazon/panfilo.png" type="image/png">
          <img width="400" height="363" class="lazyload m-auto" alt="Amazon para Cuba"
               data-src="assets/market-amazon/panfilo.png">
        </picture>
      </div>
      <div class="d-md-none position-absolute w-100 d-flex justify-content-center" style="bottom: 2%">
        <a routerLink="/market/envios/amazon-para-cuba"
           class=" btn btn-danger text-white px-3 py-2 mt-md-2 mt-lg-4 font-weight-bold OpenSans rounded-pill d-flex align-items-center justify-content-center w-75"
           style="font-size: 22px;">
          SABER MÁS</a>
      </div>
    </div>
    <picture class="position-absolute d-none d-md-block" style="z-index: 0; top: 0">
      <source data-srcset="assets/market/background.png" type="image/webp">
      <source data-srcset="assets/market/background.png" type="image/png">
      <img style="float: right" class="lazyload w-100" alt="Amazon para Cuba"
           data-src="assets/market/background.png">
    </picture>
  </div>
</section>

<!-- END DESKTOP SECTION AMAZON -->

<!-- DESKTOP SECTION REMITTANCE -->
<div id="remittance-desktop-section" class="mobile-section-1-1 d-none d-md-flex position-relative my-4 mx-auto"
  style="max-width: 1158px;">
  <div class="mobile-section-1-1-img w-100">
    <picture>
      <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/remittance.webp" type="image/webp">
      <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/remittance.png" type="image/png">
      <img class="lazyload w-100" alt="Eviar dinero a Cuba"
        data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/remittance.png">
    </picture>
  </div>
  <div class="mobile-section-1-1-container remittance-texts d-flex flex-column position-absolute align-items-start p-4"
    style="top:5%;left:5%">
    <h2 class="Poppins text-left" style="font-weight: 600;color:#003930;text-transform:none">LAS REMESAS A CUBA<br>
      NUNCA FUERON MÁS FÁCILES</h2>
    <span class="Poppins text-left mt-2 text-white font-weight-bold">Haz los envíos de dinero a Cuba<br> desde nuestra
      app.</span>
    <a routerLink="/envio-dinero-cuba"
      class="btn bg-white px-3 py-2 mt-md-2 mt-lg-4 font-weight-bold OpenSans rounded-pill d-flex align-items-center justify-content-center mr-auto w-50"
      style="color: #4fc0ad;font-size: 22px;">
      Enviar Dinero</a>
  </div>
</div>
<!-- END DESKTOP SECTION REMITTANCE -->

<!-- (NEW) DESKTOP SECTIONS COL-2 #1 -->
<div class="d-none d-md-flex justify-content-center mx-auto mt-3 ds-col-2"
  style="max-width: 1200px; min-height: 580px;">
  <div class="mobile-section-1-1 d-none d-md-flex position-relative m-2 w-100">
    <div class="m-auto">
      <img width="350" height="395" class="lazyload d-none d-md-block d-lg-none" alt="Llamadas"
        data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/background-red-496w.svg">
      <img width="496" height="560" class="lazyload d-none d-lg-block d-xl-none" alt="Llamadas"
        data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/background-red-496w.svg">
      <img width="563" height="636" class="lazyload d-none d-xl-block" alt="Llamadas"
        data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/background-red-563w.svg">
    </div>
    <div class="d-flex flex-column position-absolute align-items-center p-3 pt-4 w-100">
      <div class="w-100 d-flex justify-content-center call-text">
        <span class="Poppins text-right text-white font-weight-bold"
          style="font-size:47px; line-height:58px;flex:50%">LLAMA </span>
        <span class="Poppins font-weight-bold text-left text-white mt-2 mt-lg-3 ml-3"
          style="font-size:31px; line-height: 40px;flex:40%">a Cuba<br> fácil<br> barato<br> con Cubatel
        </span>
      </div>
    </div>
    <div class="d-flex position-absolute p-3 pt-4 w-100 text-container" style="bottom:18%">
      <div style="flex:70%"></div>
      <div style="flex:30%" class="d-flex flex-column">
        <img width="106" height="17" class="lazyload mb-1 d-none d-lg-block"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/call-section/icon-stars-white.svg"
          alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
        <img width="90" height="16" class="lazyload mb-2 d-block d-lg-none"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/call-section/icon-stars-white.svg"
          alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
        <span class="Poppins text-left text-white mb-2 mb-lg-3 text-container-call"
          style="font-size:21px; line-height: 27px;font-weight: 600;">Calidad<br> Premium
        </span>
        <span class="Poppins text-left text-white text-container-call"
          style="font-size:21px; line-height: 27px;font-weight: 600;">Sonido HD
        </span>
      </div>

    </div>
    <div class="position-absolute d-flex w-100 h-100 align-items-center mt-1 mt-lg-4 p-4">
      <picture class="d-none d-md-block d-lg-none">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/heidy.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/heidy.png" type="image/png">
        <img width="250" height="243" class="lazyload" alt="Llamadas"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/heidy.png">
      </picture>
      <picture class="d-none d-lg-block d-xl-none">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/heidy.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/heidy.png" type="image/png">
        <img width="345" height="335" class="lazyload" alt="Llamadas"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/heidy.png">
      </picture>

      <picture class="d-none d-xl-block">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/heidy.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/heidy.png" type="image/png">
        <img width="414" height="402" class="lazyload" alt="Llamadas"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/heidy.png">
      </picture>
    </div>
    <div class="position-absolute pb-lg-2 w-100 d-flex justify-content-center cta" style="bottom: 5%;">
      <button [disabled]="deepLink == 'call1'" (click)="callToAction(DeepLinkType.Calls, 'home', 'call1')"
        class="btn bg-white px-2 py-2 font-weight-bold OpenSans rounded-pill w-75 position-relative"
        style="color: #fa8a8a; font-size: 34px;">
        Llamar Ahora
        <ng-container *ngIf="deepLink != 'call1'; else snippet"></ng-container>
      </button>
    </div>
  </div>
  <!--<div class="mobile-section-1-1 d-none d-md-flex position-relative m-2 w-100 justify-content-center">
    <div class="mobile-section-1-1-img">
      <picture class="d-none d-md-block d-lg-none">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/market.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/market.png" type="image/png">
        <img width="350" height="395" class="lazyload" alt="Market"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/market.png">
      </picture>
      <picture class="d-none d-lg-block d-xl-none">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/market.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/market.png" type="image/png">
        <img width="496" height="557" class="lazyload" alt="Market"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/market.png">
      </picture>
      <picture class="d-none d-xl-block">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/market.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/market.png" type="image/png">
        <img width="564" height="633" class="lazyload" alt="Market"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/market.png">
      </picture>
    </div>
    <div class="mobile-section-1-1-container d-flex flex-column position-absolute align-items-center p-3 pt-5 market">
      <span class="Poppins text-center"
        style="color: #003930;font-size:49px; line-height:61px; font-weight: 600;">MARKET</span>
      <span class="Poppins font-weight-bold text-center text-white mt-2" style="font-size:38px; line-height: 45px;">todo
        lo
        que
        necesita tu <br>hogar en un solo lugar</span>
    </div>
    <div class="position-absolute pb-2 w-100 d-flex justify-content-center cta" style="bottom: 5%;">
      <a href="https://app.cubatel.com/stu/7ZYd"
        class="btn bg-white px-2 py-2 font-weight-bold OpenSans rounded-pill w-75"
        style="color: #47bfab; font-size: 34px;">
        Comprar Ahora
      </a>
    </div>
  </div>-->
  <div class="d-none d-md-flex position-relative m-2 w-100">
    <div class="mobile-section-1-1-img m-auto position-relative d-flex">
      <img width="350" height="395" class="lazyload d-none d-md-block d-lg-none" alt="Envíos"
        data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/background-green-496w.svg">
      <img width="496" height="560" class="lazyload d-none d-lg-block d-xl-none" alt="Envíos"
        data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/background-green-496w.svg">
      <img width="563" height="636" class="lazyload d-none d-xl-block" alt="Envíos"
        data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/background-green-563w.svg">
      <div class="position-absolute d-flex w-100 h-100 align-items-end justify-content-end">
        <picture class="d-none d-md-block d-lg-none">
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/avatar-paquete.webp"
            type="image/webp">
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/avatar-paquete.png"
            type="image/png">
          <img width="200" height="328" class="lazyload" alt="Envíos"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/avatar-paquete.png">
        </picture>
        <picture class="d-none d-lg-block d-xl-none">
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/avatar-paquete.webp"
            type="image/webp">
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/avatar-paquete.png"
            type="image/png">
          <img width="250" height="410" class="lazyload" alt="Envíos"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/avatar-paquete.png">
        </picture>
        <picture class="d-none d-xl-block">
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/avatar-paquete.webp"
            type="image/webp">
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/avatar-paquete.png"
            type="image/png">
          <img width="313" height="513" class="lazyload" alt="Envíos"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/avatar-paquete.png">
        </picture>
      </div>
    </div>
    <h2 class="d-flex flex-column position-absolute align-items-start px-5 py-4 py-lg-5 text-container-pack">
      <span class="Poppins text-left" style="color: #004d40;font-size:44px; line-height: 55px; font-weight: 600;">PAQUETERÍA</span>
      <span class="Poppins font-weight-bold text-left mt-3 mt-lg-4 text-white"
        style="font-size:44px;line-height: 55px;">Envía tus <br>libras a Cuba <br>por vía<br> marítima o <br>aérea</span>
    </h2>
    <div class="position-absolute pb-lg-2 w-100 d-flex justify-content-center cta" style="bottom: 5%;">
      <a routerLink="market" class="btn bg-white px-2 py-2 font-weight-bold OpenSans rounded-pill w-75"
        style="color: #47bfab; font-size:34px;">
        Saber más
      </a>
    </div>
  </div>
</div>
<!-- END DESKTOP SECTIONS COL-2 -->


<!-- (NEW) DESKTOP DOORS SECTION -->
<!--<div
  class="mobile-section-1-1 d-none d-md-flex position-relative py-3 mx-auto justify-content-center align-items-center ds-row-1"
  style="max-width: 1158px">
  <div>
    <picture class="d-none d-md-block d-lg-none">
      <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/doors.webp" type="image/webp">
      <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/doors.png" type="image/png">
      <img class="lazyload" width="768" height="254" alt="Ubicación"
        data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/doors.png">
    </picture>
    <picture class="d-none d-lg-block d-xl-none">
      <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/doors.webp" type="image/webp">
      <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/doors.png" type="image/png">
      <img class="lazyload" width="1024" height="338" alt="Ubicación"
        data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/doors.png">
    </picture>
    <picture class="d-none d-xl-block">
      <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/doors.webp" type="image/webp">
      <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/doors.png" type="image/png">
      <img class="lazyload" width="1160" height="396" alt="Ubicación"
        data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/doors.png">
    </picture>
  </div>
  <div class="d-flex flex-column position-absolute align-items-center p-4">
    <h2 class="Poppins text-center font-weight-bold text-white" style="font-size:42px;">Nueva oficina, nuevos
      aires</h2>
    <h2 class="Poppins text-center mt-2 visitanos" style="font-size:81px;color:#062a6f; font-weight:500"><a
        class="cursor-pointer text-white" target="_blank" style="text-decoration:none"
        href="https://www.google.com/maps?cid=1153925969485470496">¡Visítanos!</a></h2>
    <a class="cursor-pointer Poppins text-center mt-2 text-white d-flex align-items-center address"
      style="text-decoration:none;font-size:26px; line-height: 30px;font-weight:500" target="_blank"
      href="https://www.google.com/maps?cid=1153925969485470496"><img class="mr-2" alt="Ubicación" width="25"
        height="35" src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/marker.svg">734 West 49th St, Hialeah,
      FL 33012</a>
  </div>
</div>-->
<!-- END DESKTOP DOORS SECTION-->

<!-- (NEW) DESKTOP SECTIONS COL-2 #2-->
<div class="d-none d-md-flex justify-content-center m-auto ds-col-2" style="max-width: 1200px;min-height: 575px">
  <div class="mobile-section-1-1 d-none d-md-flex position-relative m-2 w-100 justify-content-center">
    <div class="mobile-section-1-1-img">
      <picture class="d-none d-md-block d-lg-none">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/market.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/market.png" type="image/png">
        <img width="350" height="395" class="lazyload" alt="Market"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/market.png">
      </picture>
      <picture class="d-none d-lg-block d-xl-none">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/market.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/market.png" type="image/png">
        <img width="496" height="557" class="lazyload" alt="Market"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/market.png">
      </picture>
      <picture class="d-none d-xl-block">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/market.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/market.png" type="image/png">
        <img width="564" height="633" class="lazyload" alt="Market"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/market.png">
      </picture>
    </div>
    <div class="mobile-section-1-1-container d-flex flex-column position-absolute align-items-center p-3 pt-5 market">
      <h2 class="Poppins text-center" style="color: #003930;font-size:49px; line-height:61px; font-weight: 600;">MARKET
      </h2>
      <span class="Poppins font-weight-bold text-center text-white mt-2" style="font-size:38px; line-height: 45px;">todo
        lo
        que
        necesita tu <br>hogar en un solo lugar</span>
    </div>
    <div class="position-absolute pb-2 w-100 d-flex justify-content-center cta" style="bottom: 5%;">
      <a routerLink="/market" class="btn bg-white px-2 py-2 font-weight-bold OpenSans rounded-pill w-75 position-relative"
        style="color: #47bfab; font-size: 34px;">
        Comprar Ahora
      </a>
    </div>
  </div>
  <div id="flights-section" class="d-none d-md-flex position-relative m-2 w-100">
    <div>
      <picture class="d-none d-md-block d-lg-none">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/vuelos.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/vuelos.png" type="image/png">
        <img width="350" height="395" class="lazyload" alt="Vuelos"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/vuelos.png">
      </picture>
      <picture class="d-none d-lg-block d-xl-none">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/vuelos.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/vuelos.png" type="image/png">
        <img width="496" height="546" class="lazyload" alt="Vuelos"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/vuelos.png">
      </picture>
      <picture class="d-none d-xl-block">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/vuelos.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/vuelos.png" type="image/png">
        <img width="575" height="633" class="lazyload" alt="Vuelos"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/vuelos.png">
      </picture>
    </div>
    <div class="d-flex flex-column position-absolute align-items-start px-5 py-5 market">
      <h2 class="Poppins text-left" style="color: #7b0e0e;font-size:52px; line-height: 58px; font-weight: 600;">VOLAR
        CON <br> CUBATEL</h2>
      <span class="Poppins font-weight-bold text-left mt-lg-4 mt-2 text-white" style="font-size:43px">nunca ha <br>sido
        más
        <br>fácil</span>
    </div>
    <div class="position-absolute pb-2 w-100 d-flex justify-content-center cta" style="bottom: 5%;">
      <a href="tel:+18332822835" class="btn bg-white px-2 py-2 font-weight-bold OpenSans rounded-pill w-75"
        style="color: #fb8e8e; font-size:34px;">
        Reservar Vuelo
      </a>
    </div>
  </div>
</div>
<!-- END DESKTOP SECTIONS COL-2 -->

<!-- (NEW) DESKTOP MAZA SECTION -->
<!--<div
  class="mobile-section-1-1 d-none d-md-flex position-relative py-3 mx-auto justify-content-center align-items-center ds-row-1"
  style="max-width: 1200px;">
  <div>
    <picture class="d-none d-md-block d-lg-none">
      <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/maza-desktop.webp" type="image/webp">
      <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/maza-desktop.png" type="image/png">
      <img class="lazyload" width="768" height="254" alt="Tarjeta de débito Visa"
        data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/maza-desktop.png">
    </picture>
    <picture class="d-none d-lg-block d-xl-none">
      <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/maza-desktop.webp" type="image/webp">
      <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/maza-desktop.png" type="image/png">
      <img class="lazyload" width="1024" height="338" alt="Tarjeta de débito Visa"
        data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/maza-desktop.png">
    </picture>
    <picture class="d-none d-xl-block">
      <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/maza-desktop.webp" type="image/webp">
      <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/maza-desktop.png" type="image/png">
      <img class="lazyload" width="1200" height="396" alt="Tarjeta de débito Visa"
        data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/maza-desktop.png">
    </picture>
  </div>
  <a class="w-100 h-100 position-absolute" style="z-index:1" href="https://click.withmaza.com/Tad2/Cubatela"
    target="_blank" aria-label="Tarjeta de débito Visa"></a>
  <div class="d-flex flex-row position-absolute align-items-center p-4 w-100 h-100">
    <div style="flex:20%"></div>
    <div class="d-flex align-items-start justify-content-start pt-2 pt-lg-4 pt-xl-5 h-100" style="flex:30%">
      <span
        class="Poppins text-left font-weight-bold text-white text-maza">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OBTÉN
        <span class="text-black">AHORA</span><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="text-black">TU</span>
        TARJETA <span class="text-black">DE</span><br>&nbsp;&nbsp;&nbsp;<span class="text-black">DÉBITO</span> SOLO
        <span class="text-black">CON</span><br><span class="text-black">TU</span> PASAPORTE</span>
    </div>
    <div style="flex:25%"></div>
    <div class="d-flex align-items-end justify-content-center h-100 text-maza text-left pb-3 mr-3" style="flex:25%">
      <span class="Poppins text-center font-weight-bold text-white">&nbsp;&nbsp;&nbsp;TARJETA<br>DE DÉBITO</span></div>
  </div>
</div>-->
<!-- END DESKTOP MAZA SECTION-->

<!-- (NEW) DESKTOP SECTIONS COL-3-->
<div id="other-services" class="d-none d-md-flex justify-content-center m-auto ds-col-3 mt-3" style="max-width: 1200px">
  <div id="rent-a-carr-section" class="d-flex position-relative m-2 m-lg-3">
    <div>
      <picture class="d-none d-md-block d-lg-none">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/carr.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/carr.png" type="image/png">
        <img width="240" height="362" class="lazyload " alt="Renta un auto"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/carr.png">
      </picture>
      <picture class="d-none d-lg-block d-xl-none">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/carr.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/carr.png" type="image/png">
        <img width="308" height="465" class="lazyload " alt="Renta un auto"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/carr.png">
      </picture>
      <picture class="d-none d-xl-block">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/carr.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/carr.png" type="image/png">
        <img width="365" height="550" class="lazyload" alt="Renta un auto"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/carr.png">
      </picture>
    </div>
    <div class="d-flex flex-column position-absolute align-items-center p-3 pt-4 ">
      <h2 class="Poppins text-center text-title"
        style="color: #960505;font-size:27px; line-height:32px; font-weight: 600;">¿PARA
        QUÉ PASAR TRABAJO?</h2>
      <span class="Poppins font-weight-bold text-center mt-xl-4 mt-3 text-white text-subtitle"
        style="font-size:24px; line-height: 29px;">Renta un auto <br>
        y muévete con
        <br>tu familia en Cuba</span>
    </div>
    <div class="position-absolute p-3 pb-4 w-100 d-flex justify-content-center" style="bottom: 0;">
      <a href="tel:+18332822835" class="btn bg-white px-4 py-2 mt-lg-5 font-weight-bold OpenSans rounded-pill w-75"
        style="color: #fb8e8e; font-size: 22px;">
        Rentar Auto
      </a>
    </div>
  </div>
  <div id="hotel-section" class="d-flex position-relative m-2 m-lg-3 justify-content-end ds-col-3">
    <div class="">
      <picture class="d-none d-md-block d-lg-none">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/reservation.webp"
          type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/reservation.png" type="image/png">
        <img width="240" height="362" class="lazyload" alt="Reservación"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/reservation.png">
      </picture>
      <picture class="d-none d-lg-block d-xl-none">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/reservation.webp"
          type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/reservation.png" type="image/png">
        <img width="307" height="467" class="lazyload" alt="Reservación"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/reservation.png">
      </picture>
      <picture class="d-none d-xl-block">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/reservation.webp"
          type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/reservation.png" type="image/png">
        <img width="363" height="553" class="lazyload" alt="Reservación"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/reservation.png">
      </picture>
    </div>
    <div class="d-flex flex-column position-absolute align-items-end p-4">
      <h2 class="Poppins text-right text-title"
        style="color: #004d40;font-size:26px; line-height:32px; font-weight: 600;">CONOCE<br> NUESTRAS OFERTAS</h2>
      <span class="Poppins font-weight-bold text-right mt-xl-4 mt-lg-2 mt-3 text-white text-subtitle"
        style="font-size:23px; line-height: 30px;">de
        hoteles <br>
        y excursiones al
        <br>mejor precio</span>
    </div>
    <div class="position-absolute p-3 pb-4 w-100 d-flex justify-content-center" style="bottom: 0;">
      <a href="tel:+18332822835" class="btn bg-white px-4 py-2 mt-lg-5 font-weight-bold OpenSans rounded-pill w-75"
        style="color: #47bfab; font-size: 22px;">
        Ver Más
      </a>
    </div>
  </div>
  <div id="formalities-section" class="d-flex position-relative m-2 m-lg-3 justify-content-center">
    <div>
      <picture class="d-none d-md-block d-lg-none">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/tramites-3.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/tramites-3.png" type="image/png">
        <img width="240" height="362" class="lazyload " alt="Vuelos"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/tramites-3.png">
      </picture>
      <picture class="d-none d-lg-block d-xl-none">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/tramites-3.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/tramites-3.png" type="image/png">
        <img width="313" height="472" class="lazyload " alt="Vuelos"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/tramites-3.png">
      </picture>
      <picture class="d-none d-xl-block">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/tramites-3.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/tramites-3.png" type="image/png">
        <img width="370" height="558" class="lazyload " alt="Vuelos"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/tramites-3.png">
      </picture>
    </div>
    <div class="d-flex flex-column position-absolute align-items-center p-3 pt-4">
      <h2 class="Poppins text-center text-title"
        style="color: #062a6f;font-size:32px; line-height:36px; font-weight: 600;">AHORRA TIEMPO</h2>
      <span class="Poppins font-weight-bold text-center mt-xl-4 mt-3 text-white text-subtitle"
        style="font-size:25px; line-height: 35px;">y
        déjanos hacer <br>
        tus trámites</span>
    </div>
    <div class="position-absolute p-3 pb-4 w-100 d-flex justify-content-center" style="bottom: 0;">
      <a href="tel:+18332822835" class="btn bg-white px-4 py-2 mt-lg-5 font-weight-bold OpenSans rounded-pill w-75"
        style="color: #6699ff; font-size: 22px;">
        Contáctanos
      </a>
    </div>
  </div>
</div>
<!-- (NEW) DESKTOP SECTIONS COL-3 -->

<!-- MOBILE SECTIONS REMITTANCE -->
<div class="mobile-section-1-1 d-flex d-md-none position-relative py-3">
  <div class="mobile-section-1-1-img m-auto position-relative d-flex justify-content-center align-items-end">
    <!--<img width="348" height="509" class="lazyload" alt="Llamadas"
      data-src="assets/home/mobile-sections-1x1/remittance.png">-->
    <picture>
      <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/remittance.webp" type="image/webp">
      <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/remittance.png" type="image/png">
      <img width="348" height="509" class="lazyload" alt="Enviar dinero a Cuba"
        data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/remittance.png">
    </picture>
    <!--<div class="position-absolute d-flex w-100 justify-content-center mb-5">
      <picture>
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/tramite.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/tramite.png" type="image/png">
        <img width="331" height="204" class="lazyload" alt="Envíos"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/tramite.png">
      </picture>
    </div>-->
  </div>
  <div class="mobile-section-1-1-container d-flex flex-column position-absolute align-items-center p-3 pt-4 w-100">
    <h2 class="Poppins text-center" style="color: #014035;font-size:29px; line-height:30px; font-weight: 600;">LAS
      REMESAS A CUBA</h2>
    <span class="Poppins text-center font-weight-bold"
      style="color: #014035;font-size:29px; line-height:36px; font-weight: 600;">NUNCA FUERON<br> MÁS FÁCILES.</span>
    <span class="Poppins font-weight-bold text-center mt-1 text-white" style="font-size:20px; line-height: 28px;">
      Haz los envíos de dinero a Cuba<br> desde nuestra app</span>
  </div>
  <div class="position-absolute w-100 d-flex justify-content-center pb-2" style="bottom: 5%;">
    <a routerLink="/envio-dinero-cuba" class="btn bg-white px-2 py-2 font-weight-bold OpenSans rounded-pill w-75"
      style="color: #47c1ad; font-size: 22px;">
      Enviar Dinero
    </a>
  </div>
</div>
<!-- END MOBILE SECTIONS REMITTANCE -->

<!-- (NEW) MOBILE SECTIONS COL-1 #1 -->

<!--<div class="mobile-section-1-1 d-flex d-md-none position-relative py-3">
  <div class="mobile-section-1-1-img m-auto position-relative d-flex">
    <img width="349" height="391" class="lazyload" alt="Llamadas"
      data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/background-red-349w.svg">
    <div class="position-absolute d-flex w-100 h-100 align-items-end justify-content-center">
      <picture>
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/doors.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/doors.png" type="image/png">
        <img width="326" height="205" class="lazyload" alt="Envíos"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/doors.png">
      </picture>
    </div>
  </div>
  <div class="mobile-section-1-1-container d-flex flex-column position-absolute align-items-center p-3 pt-4 w-100">
    <span class="Poppins text-center font-weight-bold text-white" style="font-size:29px; line-height:35px;">Nueva
      oficina,<br>
      nuevos aires</span>
    <span class="Poppins text-center" style="font-size:44px;color:#7b0e0e;font-weight:500"><a
        class="cursor-pointer text-white" style="text-decoration:none" target="_blank"
        href="https://www.google.com/maps?cid=1153925969485470496">¡Visítanos!</a></span>
  </div>
  <div class="position-absolute p-3 pb-5 w-100 d-flex align-items-center flex-column" style="bottom: 0;">
    <a class="cursor-pointer text-white d-flex flex-column align-items-center" style="text-decoration:none"
      target="_blank" href="https://www.google.com/maps?cid=1153925969485470496"><img alt="Ubicación" width="20"
        height="29" src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/desktop-sections/marker.svg">
      <span class="Poppins text-center mt-2 text-white" style="font-size:12px; line-height: 20px;font-weight:500">734
        West 49th St,<br> Hialeah, FL 33012</span></a>
  </div>
</div>-->

<div class="mobile-section-1-1 d-flex d-md-none position-relative py-3">
  <div class="mobile-section-1-1-img m-auto position-relative d-flex justify-content-center">
    <img width="349" height="391" class="lazyload" alt="Llamadas"
      data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/background-blue-349w.svg">
    <div class="position-absolute d-flex w-100 h-100 align-items-center mt-3 p-4">
      <picture>
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/heidy.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/heidy.png" type="image/png">
        <img width="250" height="250" class="lazyload" alt="Llamadas"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/heidy.png">
      </picture>
    </div>
  </div>
  <div class="mobile-section-1-1-container d-flex flex-column position-absolute align-items-center p-3 pt-4 w-100">
    <div class="w-100 d-flex justify-content-center">
      <span class="Poppins text-right text-white font-weight-bold"
        style="font-size:29px; line-height:36px;flex:50%">LLAMA </span>
      <span class="Poppins font-weight-bold text-left text-white mt-3 ml-3"
        style="font-size:19px; line-height: 24px;flex:40%">a Cuba<br> fácil<br> barato<br> con Cubatel
      </span>
    </div>
  </div>
  <div class="mobile-section-1-1-container d-flex position-absolute p-3 pt-4 w-100" style="bottom:18%">
    <div style="flex:70%"></div>
    <div style="flex:30%" class="d-flex flex-column">
      <img width="69" height="12" class="lazyload mb-2"
        data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/call-section/icon-stars-white.svg"
        alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
      <span class="Poppins text-left text-white mb-2"
        style="font-size:12px; line-height: 15px;font-weight: 600;">Calidad<br> Premium
      </span>
      <span class="Poppins text-left text-white" style="font-size:12px; line-height: 15px;font-weight: 600;">Sonido HD
      </span>
    </div>
  </div>
  <div class="position-absolute pb-2 w-100 d-flex justify-content-center" style="bottom: 5%;">
    <button [disabled]="deepLink == 'call2'" (click)="callToAction(DeepLinkType.Calls, 'home', 'call2')"
      class="btn bg-white px-2 py-2 font-weight-bold OpenSans rounded-pill w-75 position-relative"
      style="color: #6699ff; font-size: 22px;">
      Llamar Ahora
      <ng-container *ngIf="deepLink != 'call2'; else snippet"></ng-container>
    </button>
  </div>
</div>

<!--<div class="mobile-section-1-1 d-flex d-md-none position-relative py-3">
  <div class="mobile-section-1-1-img m-auto position-relative d-flex">
    <img width="349" height="391" class="lazyload" alt="Llamadas"
      data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/background-green-349w.svg">
      <div class="position-absolute d-flex w-100 justify-content-end align-items-end h-100">
        <picture>
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/avatar-envios.webp" type="image/webp">
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/avatar-envios.png" type="image/png">
          <img width="187" height="302" class="lazyload" alt="Envíos" data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/avatar-envios.png">
        </picture>
      </div>
      <div class="d-flex position-absolute w-100 h-100">
        <div class="mobile-section-1-1-container d-flex flex-column position-absolute align-items-start px-4 pt-4">
          <span class="Poppins text-left" style="color: #004d40;font-size:29px; line-height:36px; font-weight: 600;">TU
            PAQUETE:</span>
          <span class="Poppins font-weight-bold text-left mt-4 text-white" style="font-size:28px; line-height: 34px;">de
            Miami
            a <br>La Habana <br>como una <br>bala</span>
        </div>
      </div>
  </div>

  <div class="position-absolute pb-2 w-100 d-flex justify-content-center" style="bottom: 5%;">
    <a href="tel:+18332822835" class="btn bg-white px-2 py-2 font-weight-bold OpenSans rounded-pill w-75"
      style="color: #47bfab; font-size: 22px;">
      Enviar Ahora
    </a>
  </div>
</div>-->
<div class="mobile-section-1-1 d-flex d-md-none position-relative py-3">
  <div class="mobile-section-1-1-img m-auto position-relative d-flex justify-content-center align-items-end">
    <img width="349" height="391" class="lazyload" alt="Llamadas"
      data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/background-green-349w.svg">
    <div class="position-absolute d-flex w-100 justify-content-center">
      <picture>
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/combos.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/combos.png" type="image/png">
        <img width="280" height="226" class="lazyload" alt="Envíos"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/combos.png">
      </picture>
    </div>
  </div>
  <div class="mobile-section-1-1-container d-flex flex-column position-absolute align-items-center p-3 pt-4 w-100">
    <span class="Poppins text-left text-white" style="font-size:29px; line-height:36px; font-weight: 600;">MARKET</span>
    <span class="Poppins font-weight-bold text-left mt-4 text-white" style="font-size:25px; line-height: 30px;">todo lo
      que necesita tu<br>
      hogar en un solo lugar</span>
  </div>
  <div class="position-absolute pb-2 w-100 d-flex justify-content-center" style="bottom: 5%;">
    <a routerLink="/market" class="btn bg-white px-2 py-2 font-weight-bold OpenSans rounded-pill w-75 position-relative"
      style="color: #4bbfab; font-size: 22px;">
      Comprar Ahora
    </a>
  </div>
</div>
<!-- END MOBILE SECTIONS COL-1 -->

<!-- (NEW) MOBILE SECTIONS COL-2 #1 -->
<div class="d-flex d-md-none justify-content-center py-3">
  <div class="mobile-section-1-1 d-flex d-lg-none position-relative m-2">
    <div class="mobile-section-1-1-img m-auto position-relative d-flex justify-content-center align-items-end">
      <img width="167" height="226" class="lazyload" alt="Combos"
        data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/background-green-167w.svg">
      <div class="position-absolute d-flex w-100 justify-content-center p-4 mb-2">
        <picture>
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/combos-123w.webp"
            type="image/webp">
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/combos-123w.png" type="image/png">
          <img width="146" height="112" class="lazyload" alt="Envíos"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/combos-123w.png">
        </picture>
      </div>
    </div>
    <div class="mobile-section-1-1-container d-flex flex-column position-absolute align-items-start p-3 w-100">
      <span class="OpenSans text-left text-white" style="font-size:12px;">Combo</span>
      <span class="OpenSans font-weight-bold text-left text-white" style="font-size:19px">El Criollito</span>
    </div>
    <div class="position-absolute pb-2 w-100 d-flex justify-content-center" style="bottom: 5%;">
      <button [disabled]="deepLink == 'market5'" (click)="callToAction(DeepLinkType.Market, 'home-item-1', 'market5')"
        class="btn bg-white px-2 py-1 font-weight-bold OpenSans rounded-pill w-75 position-relative"
        style="color: #4bbfab; font-size: 13px;">
        Comprar Ahora
        <ng-container *ngIf="deepLink != 'market5'; else snippet"></ng-container>
      </button>
    </div>
  </div>
  <div class="mobile-section-1-1 d-flex d-md-none position-relative m-2">
    <div class="mobile-section-1-1-img m-auto position-relative d-flex justify-content-center align-items-end">
      <img width="167" height="226" class="lazyload" alt="Combos"
        data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/background-red-167w.svg">
      <div class="position-absolute d-flex w-100 justify-content-center p-4 mb-3">
        <picture>
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/combos-119w.webp"
            type="image/webp">
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/combos-119w.png" type="image/png">
          <img width="119" height="113" class="lazyload" alt="Envíos"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/combos-119w.png">
        </picture>
      </div>
    </div>
    <div class="mobile-section-1-1-container d-flex flex-column position-absolute align-items-start p-3 w-100">
      <span class="OpenSans text-left text-white" style="font-size:12px;">Combo</span>
      <span class="OpenSans font-weight-bold text-left text-white" style="font-size:19px">El Crisol</span>
    </div>
    <div class="position-absolute pb-2 w-100 d-flex justify-content-center" style="bottom: 5%;">
      <button [disabled]="deepLink == 'market6'" (click)="callToAction(DeepLinkType.Market, 'home-item-2', 'market6')"
        class="btn bg-white px-2 py-1 font-weight-bold OpenSans rounded-pill w-75"
        style="color: #fa8a8a; font-size: 13px;">
        Comprar Ahora
        <ng-container *ngIf="deepLink != 'market6'; else snippet"></ng-container>
      </button>
    </div>
  </div>
</div>
<!-- END MOBILE SECTIONS COL-2 #1 -->

<!-- (NEW) MOBILE SECTIONS COL-1 #2 -->
<div class="mobile-section-1-1 d-flex d-md-none position-relative py-3">
  <div class="mobile-section-1-1-img m-auto position-relative d-flex">
    <img width="349" height="391" class="lazyload" alt="Llamadas"
      data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/background-red-349w.svg">
    <div class="position-absolute d-flex w-100 justify-content-end align-items-end h-100">
      <picture>
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/vuelo.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/vuelo.png" type="image/png">
        <img width="187" height="302" class="lazyload" alt="Envíos"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/vuelo.png">
      </picture>
    </div>
    <div class="d-flex position-absolute w-100 h-100">
      <div class="mobile-section-1-1-container d-flex flex-column position-absolute align-items-start px-4 pt-5">
        <span class="Poppins text-left" style="color: #7b0e0e;font-size:25px; line-height:28px; font-weight: 600;">VOLAR
          CON<br> CUBATEL</span>
        <span class="Poppins font-weight-bold text-left mt-3 text-white" style="font-size:24px;">nunca ha<br>
          sido más<br>
          fácil</span>
      </div>
    </div>
  </div>

  <div class="position-absolute pb-2 w-100 d-flex justify-content-center pb-2" style="bottom: 5%;">
    <a href="tel:+18332822835" class="btn bg-white px-2 py-2 font-weight-bold OpenSans rounded-pill w-75"
      style="color: #fb8e8e; font-size: 22px;">
      Reservar Vuelo
    </a>
  </div>
</div>

<!--<div class="mobile-section-1-1 d-flex d-md-none position-relative py-3">
  <div class="mobile-section-1-1-img m-auto position-relative d-flex">
    <img width="349" height="391" class="lazyload" alt="Llamadas"
      data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/background-green-349w.svg">
      <div class="position-absolute d-flex w-100 justify-content-start align-items-end h-100 ml-4">
        <picture>
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/hotels-w.webp" type="image/webp">
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/hotels-w.png" type="image/png">
          <img width="175" height="248" class="lazyload" alt="Envíos" data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/hotels-w.png">
        </picture>
      </div>
      <div class="w-100 h-100 d-flex position-absolute">
        <div class="mobile-section-1-1-container d-flex flex-column position-absolute align-items-end p-4 w-100">
          <span class="Poppins text-right" style="color: #014035;font-size:29px; line-height:36px; font-weight: 600;">CONOCE<br> NUESTRAS OFERTAS</span>
          <span class="Poppins font-weight-bold text-right mt-1 text-white" style="font-size:24px; line-height: 32px;">de hoteles y<br>
            excursiones al<br> mejor precio</span>
        </div>
      </div>
  </div>
  <div class="position-absolute w-100 d-flex justify-content-center pb-2" style="bottom: 5%;">
    <a href="tel:+18332822835" class="btn bg-white px-2 py-2 font-weight-bold OpenSans rounded-pill w-75"
      style="color: #47c1ad; font-size: 22px;">
      Ver Más
    </a>
  </div>
</div>-->

<!--<div class="mobile-section-1-1 d-flex d-md-none position-relative py-3">
  <div class="mobile-section-1-1-img m-auto position-relative d-flex justify-content-center align-items-end">
    <img width="349" height="391" class="lazyload" alt="Llamadas"
      data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/background-blue-349w.svg">
      <div class="position-absolute d-flex w-100 justify-content-center mb-5">
        <picture>
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/carr.webp" type="image/webp">
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/carr.png" type="image/png">
          <img width="288" height="190" class="lazyload" alt="Envíos" data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/carr.png">
        </picture>
      </div>
  </div>
  <div class="mobile-section-1-1-container d-flex flex-column position-absolute align-items-center pt-3 w-100">
    <span class="Poppins text-center" style="color: #031841;font-size:29px; line-height:36px; font-weight: 600;">¿PARA QUÉ PASAR <br> TRABAJO?</span>
    <span class="Poppins font-weight-bold text-center mt-1 text-white" style="font-size:24px; line-height: 32px;">Renta un auto <br> y muévete con<br>
      tu familia en Cuba</span>
  </div>
  <div class="position-absolute w-100 d-flex justify-content-center pb-2" style="bottom: 5%;">
    <a href="tel:+18332822835" class="btn bg-white px-2 py-2 font-weight-bold OpenSans rounded-pill w-75"
      style="color: #6699ff; font-size: 22px;">
      Rentar Auto
    </a>
  </div>
</div>-->
<!-- END MOBILE SECTIONS COL-1 #2 -->

<!-- (NEW) MOBILE SECTIONS COL-2 #1 -->
<div class="d-flex justify-content-center">
  <div class="mobile-section-1-1 d-flex d-md-none position-relative m-2">
    <div class="mobile-section-1-1-img m-auto position-relative d-flex justify-content-center align-items-end">
      <img width="167" height="226" class="lazyload" alt="Combos"
        data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/background-blue-167w.svg">
      <div class="position-absolute d-flex w-100 justify-content-center p-4 mb-2">
        <picture>
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/carr.webp" type="image/webp">
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/carr.png" type="image/png">
          <img width="129" height="85" class="lazyload" alt="Renta de autos"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/carr.png">
        </picture>
      </div>
    </div>
    <div class="mobile-section-1-1-container d-flex flex-column position-absolute align-items-center p-3 w-100">
      <span class="Poppins text-center" style="font-size:13px; line-height: 18px; font-weight:600; color:#031841">¿PARA
        QUÉ PASAR<br>
        TRABAJO?</span>
      <span class="Poppins font-weight-bold text-center text-white mt-2" style="font-size:11px">Renta un auto<br>
        y muévete con<br>
        tu familia en Cuba</span>
    </div>
    <div class="position-absolute pb-2 w-100 d-flex justify-content-center" style="bottom: 0%;">
      <a href="tel:+18332822835" class="btn bg-white px-2 py-1 font-weight-bold OpenSans rounded-pill w-75"
        style="color: #6699ff; font-size: 13px;">
        Rentar Auto
      </a>
    </div>
  </div>
  <div class="mobile-section-1-1 d-flex d-md-none position-relative m-2">
    <div class="mobile-section-1-1-img m-auto position-relative d-flex justify-content-left align-items-end">
      <img width="167" height="226" class="lazyload" alt="Hoteles"
        data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/background-green-167w.svg">
      <div class="position-absolute d-flex w-100 justify-content-left mx-3">
        <picture>
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/hotels-w.webp" type="image/webp">
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/hotels-w.png" type="image/png">
          <img width="103" height="135" class="lazyload" alt="Envíos"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/hotels-w.png">
        </picture>
      </div>
    </div>
    <div class="mobile-section-1-1-container d-flex flex-column position-absolute align-items-end p-3 w-100">
      <span class="Poppins text-right"
        style="font-size:13px;line-height: 18px;color:#004d40;font-weight:600;">CONOCE<br> NUESTRAS OFERTAS</span>
      <span class="Poppins font-weight-bold text-right text-white mt-2" style="font-size:12px">de hoteles y<br>
        excursiones al<br>
        mejor precio</span>
    </div>
    <div class="position-absolute pb-2 w-100 d-flex justify-content-center" style="bottom: 0%;">
      <a href="tel:+18332822835" class="btn bg-white px-2 py-1 font-weight-bold OpenSans rounded-pill w-75"
        style="color: #4bbfab; font-size: 13px;">
        Ver Más
      </a>
    </div>
  </div>
</div>
<!-- END MOBILE SECTIONS COL-2 #1 -->

<!--<div class="mobile-section-1-1 d-flex d-md-none position-relative py-3">
  <div class="mobile-section-1-1-img m-auto position-relative d-flex justify-content-center align-items-end">
    <picture>
      <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/maza-mobile.webp" type="image/webp">
      <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/maza-mobile.png" type="image/png">
      <img width="349" height="391" class="lazyload" alt="Tarjeta de débito Visa"
        data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/maza-mobile.png">
    </picture>
  </div>
  <a class="w-100 h-100 position-absolute" style="z-index:1" href="https://click.withmaza.com/Tad2/Cubatela"
    target="_blank" aria-label="Tarjeta de débito Visa"></a>
  <div
    class="mobile-section-1-1-container d-flex flex-column position-absolute align-items-center p-3 pt-4 w-100 h-100">
    <div class="d-flex align-items-start justify-content-start pl-5 pt-2 h-100" style="flex:30%">
      <span
        class="Poppins text-left font-weight-bold text-white text-maza">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OBTÉN
        <span class="text-black">AHORA</span><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="text-black">TU</span>
        TARJETA <span class="text-black">DE</span><br>&nbsp;&nbsp;&nbsp;<span class="text-black">DÉBITO</span> SOLO
        <span class="text-black">CON</span><br><span class="text-black">TU</span> PASAPORTE</span>
    </div>
    <div class="d-flex align-items-end h-100 text-maza text-left pb-4 w-100">
      <span style="flex:7%"></span>
      <span class="Poppins text-center font-weight-bold text-white pl-sm-4" style="flex:70%"><span
          class="text-black">TARJETA</span> DE DÉBITO</span>
      <span style="flex:23%"></span>
    </div>
  </div>
</div>-->


<!-- (NEW) MOBILE SECTIONS COL-1 #3-->
<div class="mobile-section-1-1 d-flex d-md-none position-relative py-3">
  <div class="mobile-section-1-1-img m-auto position-relative d-flex justify-content-center align-items-end">
    <img width="349" height="391" class="lazyload" alt="Llamadas"
      data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/background-green-349w.svg">
    <div class="position-absolute d-flex w-100 justify-content-center mb-5">
      <picture>
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/tramite.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/tramite.png" type="image/png">
        <img width="331" height="204" class="lazyload" alt="Envíos"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-sections/tramite.png">
      </picture>
    </div>
  </div>
  <div class="mobile-section-1-1-container d-flex flex-column position-absolute align-items-center p-3 pt-4 w-100">
    <span class="Poppins text-left" style="color: #014035;font-size:29px; line-height:36px; font-weight: 600;">AHORRA
      TIEMPO</span>
    <span class="Poppins font-weight-bold text-left mt-1 text-white" style="font-size:26px; line-height: 34px;">y
      déjanos hacer<br>
      tus trámites</span>
  </div>
  <div class="position-absolute w-100 d-flex justify-content-center pb-2" style="bottom: 5%;">
    <a href="tel:+18332822835" class="btn bg-white px-2 py-2 font-weight-bold OpenSans rounded-pill w-75"
      style="color: #47c1ad; font-size: 22px;">
      Contáctanos
    </a>
  </div>
</div>
<!-- END MOBILE SECTIONS 1X1 #3 -->

<!-- (NEW) CALL MOBILE SECTION-->
<!--<div id="call-mobile-section" class="d-flex d-md-none my-3 pt-4 px-3 position-relative">
  <div id="call-mobile-background" class="w-100">
    <picture>
      <source data-srcset="assets/home/mobile-sections-1x1/llamadas.png" type="image/webp">
      <source data-srcset="assets/home/mobile-sections-1x1/llamadas.png" type="image/png">
      <img class="lazyload w-100 mt-2" alt="Llamadas" data-src="assets/home/mobile-sections-1x1/llamadas.png">
    </picture>
  </div>
  <div id="call-mobile-container" class="d-flex flex-column position-absolute align-items-start"
    style="top:0; left:5%;">
    <span class="Poppins font-weight-bold text-left" style="color: #4bbfab;font-size:24px; line-height: 1.3;"><span
        class="py-3" style="color: #004d40; font-weight: 600; font-size: 25px; line-height: 1.8;">¡LLAMA A CUBA!</span>
      <br>fácil, barato <br>con Cubatel</span>
    <img width="69" class="lazyload mt-4" data-src="assets/home/call-section/icon-stars.svg"
      alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
    <span class="Poppins font-weight-bold text-left mt-2"
      style="color: #4bbfab; font-size:16px; line-height: 1.3;">Calidad<br> Premium<br> <span
        style="line-height: 2;">Sonido HD</span></span>
  </div>
  <div class="position-absolute p-3 pb-4 w-100 d-flex justify-content-center" style="bottom: 0;">
    <a href="tel:+18332822835" class="btn bg-white px-4 py-2 mt-lg-5 font-weight-bold OpenSans rounded-pill w-75"
      style="color: #4bbfab">
      Llamar Ahora
    </a>
  </div>
</div>-->
<!-- END CALL MOBILE SECTION-->

<!-- (NEW) COL-3 SECTIONS -->
<!--<div class="d-flex flex-column flex-md-row justify-content-center m-auto" style="max-width: 1200px;">
  <div class="mobile-section-1-1 d-flex position-relative m-3">
    <div class="mobile-section-1-1-img w-100">
      <picture>
        <source media="(max-width: 756px)" data-srcset="assets/home/mobile-sections-1x1/paquete.png" type="image/webp">
        <source media="(min-width: 757px)" data-srcset="assets/home/desktop-sections/paquetes-2.png" type="image/webp">
        <source media="(max-width: 756px)" data-srcset="assets/home/mobile-sections-1x1/paquete.png" type="image/png">
        <source media="(min-width: 757px)" data-srcset="assets/home/desktop-sections/paquetes-2.png" type="image/png">
        <img class="lazyload w-100" alt="Vuelos" data-src="assets/home/mobile-sections-1x1/paquete.png">
      </picture>
    </div>
    <div class="mobile-section-1-1-container d-flex flex-column position-absolute align-items-start p-3 pt-4">
      <span class="Poppins text-left" style="color: #004d40;font-size:29px; line-height:36px; font-weight: 600;">TU
        PAQUETE:</span>
      <span class="Poppins font-weight-bold text-left mt-4 text-white" style="font-size:28px; line-height: 34px;">de
        Miami
        a <br>La Habana <br>como una <br>bala.</span>
    </div>
    <div class="position-absolute p-3 pb-4 w-100 d-flex justify-content-center" style="bottom: 0;">
      <a href="tel:+18332822835" class="btn bg-white px-4 py-2 mt-lg-5 font-weight-bold OpenSans rounded-pill w-75"
        style="color: #47bfab">
        Enviar Ahora
      </a>
    </div>
  </div>
  <div class="mobile-section-1-1 d-flex position-relative m-3">
    <div class="mobile-section-1-1-img w-100">
      <picture>
        <source media="(max-width: 756px)" data-srcset="assets/home/mobile-sections-1x1/combo.png" type="image/webp">
        <source media="(min-width: 757px)" data-srcset="assets/home/desktop-sections/combos-2.png" type="image/webp">
        <source media="(max-width: 756px)" data-srcset="assets/home/mobile-sections-1x1/combo.png" type="image/png">
        <source media="(min-width: 757px)" data-srcset="assets/home/desktop-sections/combos-2.png" type="image/png">
        <img class="lazyload w-100" alt="Vuelos" data-src="assets/home/mobile-sections-1x1/combo.png">
      </picture>
    </div>
    <div class="mobile-section-1-1-container d-flex flex-column position-absolute align-items-center p-3 pt-4">
      <span class="Poppins text-center"
        style="color: #031841;font-size:29px; line-height:36px; font-weight: 600;">MARKET</span>
      <span class="Poppins font-weight-bold text-center text-white mt-2" style="font-size:25px; line-height: 30px;">todo
        lo
        que
        necesita tu <br>hogar en un solo lugar.</span>
    </div>
    <div class="position-absolute p-3 pb-4 w-100 d-flex justify-content-center" style="bottom: 0;">
      <a href="tel:+18332822835" class="btn bg-white px-4 py-2 mt-lg-5 font-weight-bold OpenSans rounded-pill w-75"
        style="color: #6699ff">
        Comprar Ahora
      </a>
    </div>
  </div>-->
<!-- (NEW) MOBILE SECTIONS COL-2 -->
<!--<div class="d-flex justify-content-center">
    <div class="mobile-section-1-1 d-flex d-md-none position-relative m-2">
      <div class="mobile-section-1-1-img w-100">
        <picture>
          <source data-srcset="assets/home/mobile-sections-1x1/combos-col-2-1.png" type="image/webp">
          <source data-srcset="assets/home/mobile-sections-1x1/combos-col-2-1.png" type="image/png">
          <img class="lazyload w-100" alt="Vuelos" data-src="assets/home/mobile-sections-1x1/combos-col-2-1.png">
        </picture>
      </div>
      <div class="mobile-section-1-1-container d-flex flex-column position-absolute align-items-start px-3 py-2">
        <span class="OpenSans text-left text-white" style="font-size:12px;">Combos</span>
        <span class="Poppins font-weight-bold text-left text-white" style="font-size:19px;">El Central</span>
      </div>
      <div class="position-absolute pb-3 w-100 d-flex justify-content-center" style="bottom: 0;">
        <a href="tel:+18332822835" class="btn bg-white px-2 py-1 mt-lg-5 font-weight-bold OpenSans rounded-pill w-75"
          style="color: #6699ff; font-size: 13px;">
          Comprar Ahora
        </a>
      </div>
    </div>
    <div class="mobile-section-1-1 d-flex d-md-none position-relative m-2">
      <div class="mobile-section-1-1-img w-100">
        <picture>
          <source data-srcset="assets/home/mobile-sections-1x1/combos-col-2-2.png" type="image/webp">
          <source data-srcset="assets/home/mobile-sections-1x1/combos-col-2-2.png" type="image/png">
          <img class="lazyload w-100" alt="Vuelos" data-src="assets/home/mobile-sections-1x1/combos-col-2-2.png">
        </picture>
      </div>
      <div class="mobile-section-1-1-container d-flex flex-column position-absolute align-items-start px-3 py-2">
        <span class="OpenSans text-left text-white" style="font-size:12px;">Combos</span>
        <span class="Poppins font-weight-bold text-left text-white" style="font-size:19px;">El Criollito</span>
      </div>
      <div class="position-absolute pb-3 w-100 d-flex justify-content-center" style="bottom: 0;">
        <a href="tel:+18332822835" class="btn bg-white px-2 py-1 mt-lg-5 font-weight-bold OpenSans rounded-pill w-75"
          style="color: #6699ff; font-size: 13px;">
          Comprar Ahora
        </a>
      </div>
    </div>
  </div>-->
<!-- END MOBILE SECTIONS COL-2 -->
<!--<div class="mobile-section-1-1 d-flex position-relative m-3">
    <div class="mobile-section-1-1-img w-100">
      <picture>
        <source media="(max-width: 756px)" data-srcset="assets/home/mobile-sections-1x1/vuelos.png" type="image/webp">
        <source media="(min-width: 757px)" data-srcset="assets/home/desktop-sections/vuelos.png" type="image/webp">
        <source media="(max-width: 756px)" data-srcset="assets/home/mobile-sections-1x1/vuelos.png" type="image/png">
        <source media="(min-width: 757px)" data-srcset="assets/home/desktop-sections/vuelos.png" type="image/png">
        <img class="lazyload w-100" alt="Vuelos" data-src="assets/home/mobile-sections-1x1/vuelos.png">
      </picture>
    </div>
    <div class="mobile-section-1-1-container d-flex flex-column position-absolute align-items-start p-3 pt-4">
      <span class="Poppins text-left" style="color: #960505;font-size:24px; line-height: 1.2; font-weight: 600;">NUEVA
        OFICINA, <br>NUEVOS
        AIRES.</span>
      <span class="Poppins font-weight-bold text-left mt-4 text-white" style="font-size:23px; line-height: 1.2;">Volar
        con
        <br>Cubatel <br> nunca ha sido <br>más fácil.</span>
    </div>
    <div class="position-absolute p-3 pb-4 w-100 d-flex justify-content-center" style="bottom: 0;">
      <a href="tel:+18332822835" class="btn bg-white px-4 py-2 mt-lg-5 font-weight-bold OpenSans rounded-pill w-75"
        style="color: #fb8e8e">
        Reservar Vuelo
      </a>
    </div>
  </div>



</div>-->
<!-- END COL-3 SECTIONS -->

<!--<div class="mobile-section-1-1 d-flex d-md-none position-relative m-3">
  <div class="mobile-section-1-1-img w-100">
    <picture>
      <source data-srcset="assets/home/mobile-sections-1x1/remesas.png" type="image/webp">
      <source data-srcset="assets/home/mobile-sections-1x1/remesas.png" type="image/png">
      <img class="lazyload w-100" alt="Vuelos" data-src="assets/home/mobile-sections-1x1/remesas.png">
    </picture>
  </div>
  <div class="mobile-section-1-1-container d-flex flex-column position-absolute align-items-start p-3 pt-4">
    <span class="Poppins text-left" style="color: #960505;font-size:24px; line-height: 1.2; font-weight: 600;">ENVÍA
      DINERO</span>
    <span class="Poppins font-weight-bold text-left mt-3 text-white" style="font-size:23px; line-height: 1.2;">a tus
      familiares <br>en Cuba de <br>forma rápida <br>y segura.</span>
  </div>
  <div class="position-absolute p-3 pb-5 w-100 d-flex justify-content-center" style="bottom: 0;">
    <a href="tel:+18332822835" class="btn bg-white px-4 py-2 mt-lg-5 font-weight-bold OpenSans rounded-pill w-75"
      style="color: #fb8e8e">
      Transferir Ahora!</a>
  </div>
</div>-->
<!-- END MOBILE SECTIONS 1X1 -->

<!-- (NEW) MOBILE SECTIONS LARGE 1X1 -->
<!--<div class="mobile-section-1-1 d-flex d-md-none position-relative m-3">
  <div class="mobile-section-1-1-img w-100">
    <picture>
      <source data-srcset="assets/home/mobile-sections-1x1/remesas1.png" type="image/webp">
      <source data-srcset="assets/home/mobile-sections-1x1/remesas1.png" type="image/png">
      <img class="lazyload w-100" alt="Remesas" data-src="assets/home/mobile-sections-1x1/remesas1.png">
    </picture>
  </div>
  <div class="mobile-section-1-1-container d-flex flex-column position-absolute align-items-start p-3 pt-3">
    <span class="Poppins text-left text-white" style="font-size:16px; line-height: 20px; font-weight: 600;">Envía dinero
      a tus <br>familiares en Cuba</span>
    <span class="Poppins text-left mt-2" style="font-size:20px; line-height: 1.2;color:#031841; font-weight: 600;">DE
      FORMA RÁPIDA <br>Y SEGURA</span>
  </div>
  <div class="position-absolute p-3 w-100 d-flex justify-content-start" style="bottom: 0;">
    <a href="tel:+18332822835"
      class="btn bg-white pl-3 pr-1 py-1 mt-lg-5 font-weight-bold OpenSans rounded-pill position-relative text-left d-flex align-items-center justify-content-between"
      style="color: #4282ff; width:55%; font-size: 14px;">
      Enviar Remsesa <img width="26" class="lazyload" data-src="assets/home/mobile-sections-1x1/arrow-right.svg"
        alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba"></a>
  </div>
</div>-->
<!-- END MOBILE SECTIONS LARGE 1X1 -->

<!-- (NEW) DESKTOP SECTIONS LARGE 1X1 -->
<!--<div class="mobile-section-1-1 d-none d-md-flex position-relative my-4 mx-auto" style="max-width: 1200px;">
  <div class="mobile-section-1-1-img w-100">
    <picture>
      <source data-srcset="assets/home/desktop-sections/remesas.png" type="image/webp">
      <source data-srcset="assets/home/desktop-sections/remesas.png" type="image/png">
      <img class="lazyload w-100" alt="Vuelos" data-src="assets/home/desktop-sections/remesas.png">
    </picture>
  </div>
  <div class="mobile-section-1-1-container d-flex flex-column position-absolute align-items-center p-4"
    style="right:10%; top:5%">
    <span class="Poppins text-center" style="font-size:50px; line-height: 50px; font-weight: 600;color:#003930">ENVÍA
      DINERO</span>
    <span class="Poppins text-center mt-2 text-white font-weight-bold" style="font-size:39px; line-height: 51px;">a tus
      familiares en Cuba de<br> forma rápida y segura</span>
    <a href="tel:+18332822835"
      class="btn bg-white px-3 py-2 mt-lg-5 font-weight-bold OpenSans rounded-pill d-flex align-items-center justify-content-center m-auto w-75"
      style="color: #4bbfab;font-size: 22px;">
      Transferir Ahora</a>
  </div>
</div>

<div class="mobile-section-1-1 d-none d-md-flex position-relative my-4 mx-auto" style="max-width: 1200px;">
  <div class="mobile-section-1-1-img w-100">
    <picture>
      <source data-srcset="assets/home/desktop-sections/llamadas.png" type="image/webp">
      <source data-srcset="assets/home/desktop-sections/llamadas.png" type="image/png">
      <img class="lazyload w-100" alt="Vuelos" data-src="assets/home/desktop-sections/llamadas.png">
    </picture>
  </div>
  <div class="mobile-section-1-1-container d-flex flex-column position-absolute align-items-center p-4"
    style="left:10%; top:5%">
    <span class="Poppins text-center" style="font-size:50px; line-height: 50px; font-weight: 600;color:#620606">LLAMA A
      CUBA</span>
    <span class="Poppins text-center mt-2 text-white font-weight-bold" style="font-size:39px; line-height: 51px;">fácil,
      barato con Cubatel</span>
    <img width="145" class="lazyload mt-4" data-src="assets/home/call-section/icon-stars-red.svg"
      alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
    <span class="Poppins text-center mt-2 text-white" style="font-size:18px; line-height: 1.3;font-weight: 600;">Calidad
      Premium <span class="ml-2" style="line-height: 2;">Sonido HD</span></span>
    <a href="tel:+18332822835"
      class="btn bg-white px-3 py-2 mt-md-4 font-weight-bold OpenSans rounded-pill d-flex align-items-center justify-content-center m-auto w-75"
      style="color: #fb8e8e;font-size: 22px;">
      Llamar Ahora</a>
  </div>
</div>

<div class="mobile-section-1-1 d-none d-md-flex position-relative my-3 mx-auto" style="max-width: 1200px;">
  <div class="mobile-section-1-1-img w-100">
    <picture>
      <source data-srcset="assets/home/desktop-sections/tramites.png" type="image/webp">
      <source data-srcset="assets/home/desktop-sections/tramites.png" type="image/png">
      <img class="lazyload w-100" alt="Vuelos" data-src="assets/home/desktop-sections/tramites.png">
    </picture>
  </div>
  <div class="mobile-section-1-1-container d-flex flex-column position-absolute align-items-center p-4"
    style="right:10%; top:5%">
    <span class="Poppins text-center" style="font-size:50px; line-height: 50px; font-weight: 600;color:#062a6f">AHORRA
      TIEMPO</span>
    <span class="Poppins text-center mt-2 text-white font-weight-bold" style="font-size:39px; line-height: 51px;">y
      déjanos hacer<br> tus trámites</span>
    <a href="tel:+18332822835"
      class="btn bg-white px-3 py-2 mt-lg-5 font-weight-bold OpenSans rounded-pill d-flex align-items-center justify-content-center m-auto w-75"
      style="color: #6f9efb;font-size: 22px;">
      Contáctanos</a>
  </div>
</div>-->
<!-- (NEW) DESKTOP SECTIONS LARGE 1X1 -->

<!-- (NEW) DESKTOP SECTIONS COL-2 -->
<!--<div class="d-flex justify-content-center mx-auto my-4" style="max-width: 1200px;">
  <div class="mobile-section-1-1 d-none d-md-flex position-relative m-2 w-100">
    <div class="mobile-section-1-1-img w-100 pt-5">
      <picture>
        <source data-srcset="assets/home/desktop-sections/remesas-2.png" type="image/webp">
        <source data-srcset="assets/home/desktop-sections/remesas-2.png" type="image/png">
        <img class="lazyload w-100" alt="Vuelos" data-src="assets/home/desktop-sections/remesas-2.png">
      </picture>
    </div>
    <div class="mobile-section-1-1-container d-flex flex-column position-absolute align-items-start p-4">
      <span class="Poppins text-left pt-3"
        style="font-size:43px;color: #004d40;font-weight:600; line-height: 54px;">ENVÍA DINERO</span>
      <span class="Poppins font-weight-bold text-left pt-3" style="font-size:31px;line-height: 38px;color:#4bbfab">a tus
        familiares<br> en Cuba de<br> forma rápida<br> y segura</span>
    </div>
    <div class="position-absolute p-4 d-flex justify-content-start" style="bottom: 4%;">
      <a href="tel:+18332822835" class="btn bg-white px-5 py-2 font-weight-bold OpenSans rounded-pill w-100"
        style="color: #4bbfab; font-size: 22px;">
        Transferir Ahora
      </a>
    </div>
  </div>
  <div class="mobile-section-1-1 d-none d-md-flex position-relative m-2 w-100">
    <div class="mobile-section-1-1-img w-100 pt-5">
      <picture>
        <source data-srcset="assets/home/desktop-sections/llamadas-2.png" type="image/webp">
        <source data-srcset="assets/home/desktop-sections/llamadas-2.png" type="image/png">
        <img class="lazyload w-100" alt="Vuelos" data-src="assets/home/desktop-sections/llamadas-2.png">
      </picture>
    </div>
    <div class="mobile-section-1-1-container d-flex flex-column position-absolute align-items-start p-4">
      <span class="Poppins text-left pt-3"
        style="font-size:43px;color: #004d40;font-weight:600; line-height: 54px;">LLAMA A CUBA</span>
      <span class="Poppins font-weight-bold text-left pt-2"
        style="font-size:31px;line-height: 38px;color:#ed6d6d;">fácil, barato<br> con Cubatel</span>
      <img width="145" class="lazyload mt-3" data-src="assets/home/call-section/icon-stars.svg"
        alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
      <span class="Poppins mt-3" style="font-size:18px; line-height: 1.3;font-weight: 600; color:#ed6d6d">Calidad
        Premium <br><span style="line-height: 2;">Sonido HD</span></span>
    </div>
    <div class="position-absolute p-4 d-flex justify-content-start" style="bottom: 4%;">
      <a href="tel:+18332822835" class="btn bg-white px-5 py-2 font-weight-bold OpenSans rounded-pill w-100"
        style="color: #ed6d6d; font-size: 22px;">
        Llamar Ahora
      </a>
    </div>
  </div>
</div>-->
<!-- END DESKTOP SECTIONS COL-2 -->

<!-- (NEW) DESKTOP SECTIONS XX LARGE 1X1 -->
<!--<div class="d-none d-md-flex my-5 w-100"
  style="height: 760px; background-image: linear-gradient(65deg, #86acf6 0%, #538dff 64%, #1f6bff 91%, #1464ff 100%);">
  <div class="mx-auto d-flex align-items-center" style="width:1200px">
    <div class="mobile-section-1-1-container d-flex flex-column align-items-left p-4" style="left:10%; top:5%">
      <span class="Poppins text-left" style="font-size:69px; line-height: 69px; font-weight: 600;color:#002369">LLAMA A
        CUBA</span>
      <span class="Poppins text-left mt-2 text-white font-weight-bold" style="font-size:63px; line-height: 82px;">fácil,
        barato<br> con Cubatel</span>
      <img width="145" class="lazyload my-4" data-src="assets/home/call-section/icon-stars-blue.svg"
        alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
      <span class="Poppins text-left mt-2 text-white"
        style="font-size:25px; line-height: 36px;font-weight: 600;">Calidad Premium<br> Sonido HD</span>
      <a href="tel:+18332822835"
        class="btn bg-white px-3 py-2 mt-md-5 font-weight-bold OpenSans rounded-pill d-flex align-items-center justify-content-center w-75"
        style="color: #6699ff;font-size: 22px;">
        Llamar Ahora</a>
    </div>
    <picture class="align-self-end">
      <source data-srcset="assets/home/desktop-sections/llamada-avatar.png" type="image/webp">
      <source data-srcset="assets/home/desktop-sections/llamada-avatar.png" type="image/png">
      <img class="lazyload w-100" alt="Vuelos" data-src="assets/home/desktop-sections/llamada-avatar.png">
    </picture>
  </div>

</div>-->
<!-- END DESKTOP SECTIONS XX LARGE 1X1 -->

<!-- (NEW) DESKTOP SECTIONS EXTRA LARGE 1X1 -->
<!--<div class="mobile-section-1-1 d-none d-md-flex position-relative my-5 mx-auto" style="max-width: 1200px">
  <div class="mobile-section-1-1-img w-100" style="padding-top: 14rem">
    <picture>
      <source data-srcset="assets/home/desktop-sections/tramites-2.png" type="image/webp">
      <source data-srcset="assets/home/desktop-sections/tramites-2.png" type="image/png">
      <img class="lazyload w-100" alt="Vuelos" data-src="assets/home/desktop-sections/tramites-2.png">
    </picture>
  </div>
  <div class="mobile-section-1-1-container d-flex flex-column position-absolute align-items-center p-3 pt-4 w-100">
    <span class="Poppins text-center" style="color: #620606;font-size:50px; line-height:50px; font-weight: 600;">AHORRA
      TIEMPO</span>
    <span class="Poppins font-weight-bold text-center mt-2" style="font-size:46px; line-height: 60px;color:#fb8e8e;">y
      déjanos hacer tus trámites</span>
    <a href="tel:+18332822835" class="btn text-white px-4 py-2 mt-lg-4 font-weight-bold OpenSans rounded-pill w-75"
      style="background-color: #fb8e8e; max-width:320px">
      Contáctanos
    </a>
  </div>
</div>-->

<!--<div class="mobile-section-1-1 d-none d-md-flex position-relative my-5 mx-auto" style="max-width: 1200px;">
  <div class="mobile-section-1-1-img w-100">
    <picture>
      <source data-srcset="assets/home/desktop-sections/remesas-3.png" type="image/webp">
      <source data-srcset="assets/home/desktop-sections/remesas-3.png" type="image/png">
      <img class="lazyload w-100" alt="Vuelos" data-src="assets/home/desktop-sections/remesas-3.png">
    </picture>
  </div>
  <div class="mobile-section-1-1-container d-flex flex-column position-absolute align-items-end p-4"
    style="right:5%; top:10%">
    <span class="Poppins text-right" style="font-size:66px; line-height: 66px; font-weight: 600;color:#003930">ENVÍA
      DINERO</span>
    <span class="Poppins text-right mt-4 text-white font-weight-bold" style="font-size:52px; line-height: 70px;">a tus
      familiares<br> en Cuba de<br> forma rápida<br> y segura</span>
    <a href="tel:+18332822835"
      class="btn bg-white px-4 py-2 mt-lg-5 font-weight-bold OpenSans rounded-pill d-flex align-items-center justify-content-center w-100"
      style="color: #4bbfab;font-size: 22px;">
      Transferir Ahora</a>
  </div>
</div>-->

<!-- END DESKTOP SECTIONS EXTRA LARGE 1X1 -->

<!-- (OLD) CALL SECTION-->
<!--<div id="call" class="d-flex stu-bg-danger pt-xl-5">
  <div class="d-flex flex-column-reverse justify-content-center flex-lg-row py-4 pb-md-0 pt-xxl-5">
    <a routerLink="/llamadas-a-cuba"
      class="btn btn-primary stu-bg-primary text-white px-4 py-2 mt-lg-5 font-weight-bold OpenSans d-block d-lg-none w-75 align-self-center mb-4 rounded-pill">
      ¡Llama Ahora!
    </a>
    <div class="d-flex mt-4 position-relative mr-lg-2 pr-xl-5 mr-xl-5 justify-content-center">
      <picture class="d-flex d-md-none">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/heidy.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/heidy.png" type="image/png">
        <img width="249" height="256" class="lazyload" data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/heidy.png"
          alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
      </picture>
      <picture class="d-none d-md-flex">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/heidy.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/heidy.png" type="image/png">
        <img width="405" class="lazyload" data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/heidy.png"
          alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
      </picture>
      <picture id="cent-min" class="position-absolute d-none d-lg-flex">
        <source data-source="https://www.cubatel.com/images-ads/cdn/assets-v3/home/0.69.webp" type="image/webp" />
        <source data-source="https://www.cubatel.com/images-ads/cdn/assets-v3/home/0.69.png" type="image/png" />
        <img width="158" class="lazyload" data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/0.69.png" alt="$0.69/min">
      </picture>
      <picture id="cent-min" class="position-absolute d-flex d-lg-none">
        <source data-source="https://www.cubatel.com/images-ads/cdn/assets-v3/home/0.69.webp" type="image/webp" />
        <source data-source="https://www.cubatel.com/images-ads/cdn/assets-v3/home/0.69.png" type="image/png" />
        <img width="91" class="lazyload" data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/0.69.png" alt="$0.69/min">
      </picture>
    </div>
    <div class="d-flex flex-column mt-4 ml-lg-5 pl-xl-5 justify-content-center">
      <div id="call-info" class="d-flex justify-content-md-center">
        <div class="d-flex flex-column mr-4 mr-lg-5">
          <span class="Poppins text-white text-right font-weight-bold">Llama</span>
          <span class="Poppins text-white text-right">a Cuba<br>
            fácil, barato<br>
            con <span class="font-weight-bold">Cubatel</span>
          </span>
        </div>
        <div class="d-flex flex-column pl-4 pl-lg-5 pt-3">
          <img width="145" class="lazyload d-none d-lg-flex"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/white-stars.svg"
            alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
          <img width="74" height="12" class="lazyload d-flex d-lg-none"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/white-stars.svg"
            alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
          <span class="Poppins text-white mt-3 mt-lg-2">Calidad<br> Premium</span>
          <span class="Poppins text-white mt-2">Sonido HD</span>
        </div>
      </div>
      <a routerLink="/llamadas-a-cuba"
        class="btn btn-primary stu-bg-primary text-white px-4 py-3 mt-lg-5 font-weight-bold OpenSans d-none d-lg-block">
        ¡Llama Ahora!
      </a>
    </div>
  </div>
</div>-->
<!-- END CALL SECTION-->

<!-- (NEW) PROMOTIONS SECTION -->
<div id="promotions" class="d-flex justify-content-center position-relative pt-3">
  <div id="promotions-background">
    <picture>
      <source media="(max-width: 756px)"
        data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/promo-section/background-promo-mobile.webp 320w"
        type="image/webp">
      <source media="(max-width: 1199px)"
        data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/promo-section/background-1024.webp 1024w" type="image/webp">
      <source media="(max-width: 2499)"
        data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/promo-section/background-1336.webp 1336w" type="image/webp">
      <source media="(min-width: 2500px)"
        data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/promo-section/background-2560.webp 2560w" type="image/webp">
      <source media="(max-width: 756px)"
        data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/promo-section/background-promo-mobile.png 320w"
        type="image/png">
      <source media="(max-width: 1199px)"
        data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/promo-section/background-1024.jpg 1024w" type="image/png">
      <source media="(max-width: 2499px)"
        data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/promo-section/background-1336.jpg 1336w" type="image/png">
      <source media="(min-width: 2500px)"
        data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/promo-section/background-2560.jpg 2560w" type="image/png">
      <img class="lazyload" alt="Promociones"
        data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/promo-section/background-promo.png">
    </picture>
  </div>
  <div id="promotions-container" class="d-flex flex-column align-items-center position-absolute pt-4 h-100">
    <h2 class="Poppins text-white text-center d-flex flex-column align-items-center flex-md-row px-3 pt-md-3 pt-xl-5">
      <img width="54" height="52" class="lazyload mr-3 mb-2 icon-cubatel"
        data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/promo-section/icon-cubatel.svg"
        alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba"> ¡Bienvenido! Promoción de recarga a Cuba</h2>
    <!--<div id="promotionsInfo" class="d-none d-md-flex flex-column flex-md-row justify-content-md-center m-auto pt-xl-5">
      <div class="px-lg-4 d-flex flex-column align-items-center">
        <p *ngIf="!isAuthenticated()" class="mb-0 mt-1 mb-4 text-white text-center w-75 m-auto">Usa el código
          <span>DESCUENTO10</span> y envía tu primera
          recarga por solo <span>$19.59 USD</span>
        </p>
        <p *ngIf="isAuthenticated() " class="mb-0 mt-1 mb-4 text-white text-center w-75 m-auto"><span>Más
            OFERTAS</span><br>
          Elige enviar saldo, planes combinados o la promoción de recarga disponible a Cuba</p>
        <button (click)="callToAction1('recharge')"
          class="btn bg-white font-weight-bold OpenSans rounded-pill mt-4 stu-promotion-color">
          Enviar Recarga
        </button>
      </div>
      <div class="px-lg-4 d-flex flex-column align-items-center promo-into-phone">
        <p *ngIf="!isAuthenticated()" class="mb-0 mt-1 mb-4 text-white text-center w-75 m-auto">¡Llama a
          <span>Cuba</span>! Instala nuestra app y habla
          <span>10 minutos por $1 USD</span>
        </p>
        <p *ngIf="isAuthenticated() " class="mb-0 mt-1 mb-4 text-white text-center w-75 m-auto"> <span>SMS
            GRATIS</span><br>
          Gana 3 SMS gratis por cada recarga completada y uno de cortesía al enviarla</p>
        <a *ngIf="!isAuthenticated()" routerLink="/llamadas-a-cuba" routerLinkActive=""
          class="btn bg-white font-weight-bold OpenSans rounded-pill mt-4 stu-promotion-color">
          Llamar Ahora
        </a>
        <a *ngIf="isAuthenticated()" routerLink="/sms" routerLinkActive=""
          class="btn bg-white font-weight-bold OpenSans rounded-pill mt-4 stu-promotion-color">
          Enviar SMS
        </a>
      </div>
      <div class="px-lg-4 d-flex flex-column align-items-center">
        <p *ngIf="!isAuthenticated()" class="mb-0 mt-1 mb-4 text-white text-center w-75 m-auto">Regístrate en
          <span>Cubatel</span> y obtén <span>10 SMS
            gratis</span>. ¡Comunícate con tu gente!
        </p>
        <p *ngIf="isAuthenticated()" class="mb-0 mt-1 mb-4 text-white text-center w-75 m-auto"><span>Llamadas a
            Cuba</span><br>
          Con calidad Premium, desde cualquier país y mediante datos, Wi-Fi o número local</p>
        <a *ngIf="!isAuthenticated()" routerLink="/sms" routerLinkActive=""
          class="btn bg-white font-weight-bold OpenSans rounded-pill mt-4">
          Enviar SMS
        </a>
        <a *ngIf="isAuthenticated()" routerLink="/llamadas-a-cuba" routerLinkActive=""
          class="btn bg-white font-weight-bold OpenSans rounded-pill mt-4 stu-promotion-color">
          Llamar Ahora
        </a>
      </div>
    </div>-->
    <div id="sliders-promotions" class="carousel slide d-block mt-auto pb-5 w-75 h-50" data-touch="true"
      data-pause="false" data-ride="carousel">
      <ol class="carousel-indicators">
        <li data-target="#sliders-promotions" data-slide-to="0" class="active ml-md-3 ml-xl-0"></li>
        <li data-target="#sliders-promotions" data-slide-to="1"></li>
      </ol>
      <div class="carousel-inner m-auto">
        <div class="carousel-item mb-2 active" data-interval="20000" data-index="0">
          <div class="d-flex flex-column align-items-center">
            <p *ngIf="!isAuthenticated()" class="text-center mt-4 mt-md-1 text-white ml-md-3 ml-xl-0 px-3 px-md-1">Usa
              el código
              <span>OFERTA</span> y recibe descuentos
              adicionales en cada una de tus recargas de 500 CUP o m&aacute;s
            </p>
            <p *ngIf="isAuthenticated()" class="text-center mt-4 mt-md-1 text-white ml-md-3 ml-xl-0 px-3 px-md-1">
              <span>Más
                OFERTAS</span><br>
              Elige enviar saldo, planes combinados o la promoción de recarga disponible a Cuba</p>
            <button (click)="callToAction1('recharge')" class="btn bg-white font-weight-bold OpenSans rounded-pill mt-lg-auto stu-promotion-color ml-md-3
              ml-xl-0">
              Enviar Recarga
            </button>
          </div>
        </div>
        <!--<div class="carousel-item mb-2" data-interval="20000" data-index="1">
          <div class="d-flex flex-column align-items-center">
            <p *ngIf="!isAuthenticated()" class="text-center mt-4 px-3 text-white">¡Llama a <span>Cuba</span>! Instala
              nuestra app y
              habla
              <span>10 minutos por $1 USD</span>
            </p>
            <p *ngIf="isAuthenticated()" class="text-center mt-4 px-3 text-white"><span>SMS GRATIS</span><br>
              Gana 3 SMS gratis por cada recarga completada y uno de cortesía al enviarla</p>
            <a *ngIf="!isAuthenticated()" routerLink="/llamadas-a-cuba" routerLinkActive=""
              class="btn bg-white font-weight-bold OpenSans rounded-pill mt-lg-auto stu-promotion-color">
              Llamar Ahora
            </a>
            <a *ngIf="isAuthenticated()" routerLink="/sms" routerLinkActive=""
              class="btn bg-white font-weight-bold OpenSans rounded-pill mt-lg-auto stu-promotion-color">
              Enviar SMS
            </a>
          </div>
        </div>-->
        <div class="carousel-item mb-2" data-interval="20000" data-index="1">
          <div class="d-flex flex-column align-items-center">
            <p *ngIf="!isAuthenticated()" class="text-center mt-4 mt-md-1 text-white ml-md-3 ml-xl-0 px-3 px-md-1">
              Regístrate en
              <span>Cubatel</span> y
              obtén <span>10
                SMS
                gratis</span>. ¡Comunícate con tu gente!</p>
            <p *ngIf="isAuthenticated()" class="text-center mt-4 mt-md-1 text-white ml-md-3 ml-xl-0 px-3 px-md-1">
              <span>Llamadas a
                Cuba</span><br>
              Con calidad Premium, desde cualquier país y mediante datos, Wi-Fi o número local</p>
            <a *ngIf="!isAuthenticated()" routerLink="/sms" routerLinkActive="" class="btn bg-white font-weight-bold OpenSans rounded-pill mt-md-auto stu-promotion-color ml-md-3
              ml-xl-0">
              Enviar SMS
            </a>
            <a *ngIf="isAuthenticated()" routerLink="/llamadas-a-cuba" routerLinkActive="" class="btn bg-white font-weight-bold OpenSans rounded-pill mt-md-auto stu-promotion-color ml-md-3
              ml-xl-0">
              Llamar Ahora
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <picture class="d-none d-md-flex position-absolute phone-picture" style="bottom:0;z-index: 0;">
    <source media="(max-width: 1199px)"
      data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/promo-section/phone-1024v2.webp 1024w" type="image/webp">
    <source media="(max-width: 2499)"
      data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/promo-section/phone-1336v2.webp 1336w" type="image/webp">
    <source media="(min-width: 2500px)"
      data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/promo-section/phone-2560v3.webp 2560w" type="image/webp">
    <source media="(max-width: 1199px)"
      data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/promo-section/phone-1024v2.png 1024w" type="image/png">
    <source media="(max-width: 2499px)"
      data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/promo-section/phone-1336v2.png 1336w" type="image/png">
    <source media="(min-width: 2500px)"
      data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/promo-section/phone-2560v3.png 2560w" type="image/png">
    <img class="lazyload" alt="Promociones"
      data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/promo-section/phone-1336v2.png">
  </picture>
</div>
<!-- END PROMOTIONS SECTION -->

<!-- STATISTICS SECTION-->
<div id="statistics"
  class="d-flex flex-column flex-md-row justify-content-center align-items-center align-items-md-start">
  <div id="user-counter" class="d-flex flex-column align-items-center mx-lg-4 mx-xxl-5">
    <img width="70" height="70" class="lazyload"
      data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/stu-icon-add-users-circle.svg"
      alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
    <span class="Poppins font-weight-bold mt-1">{{userCounter | number: '1.0':'en-US'}} <span
        *ngIf="userCounter == 300000">+</span></span>
    <p class="Poppins text-center mt-2 px-md-2">Usuarios</p>
  </div>
  <div class="d-flex flex-column align-items-center mt-4 mt-md-0 mx-lg-4 mx-xxl-5">
    <img width="70" height="70" class="lazyload"
      data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/stu-icon-download-circle.svg"
      alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
    <span class="Poppins font-weight-bold mt-1">{{appsCounter | number: '1.0':'en-US'}} <span
        *ngIf="appsCounter == 500000">+</span></span>
    <p class="Poppins text-center mt-2 px-md-2">Descargas en Google Play
      y App Store </p>
  </div>
  <div class="d-flex flex-column align-items-center mt-4 mt-md-0 mx-lg-4 mx-xxl-5">
    <img width="70" height="70" class="lazyload"
      data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/stu-icon-marker-circle.svg"
      alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
    <span class="Poppins font-weight-bold mt-1">{{countriesCounter}}</span>
    <p class="Poppins text-center mt-2 px-md-2">Países con usuarios
      de Cubatel </p>
  </div>
  <div class="d-flex flex-column align-items-center mt-4 mt-md-0 mx-lg-4 mx-xxl-5">
    <img width="70" height="70" class="lazyload"
      data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/stu-icon-security-circle.svg"
      alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
    <span class="Poppins font-weight-bold mt-md-2 mt-xl-3">Pago Seguro</span>
    <p class="Poppins text-center mt-md-2 px-md-2 mt-xl-3">Sistema encriptado
      TLS</p>
  </div>
</div>
<!-- END STATISTICS SECTION-->

<!-- APPS SECTION # 1 -->
<div id="appsSection1" class="d-flex flex-column flex-lg-row">
  <div>
    <h2 class="Poppins d-none d-lg-flex">¡Hazte Cubatel!</h2>
    <div id="as1-links-apps" class="mt-4 mb-5 d-flex justify-content-center flex-column flex-lg-row">

      <a class="btn btn-secondary bg-transparent text-decoration-none mr-lg-3 "
        href="https://play.google.com/store/apps/details?id=com.cubatel.android&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        rel="noreferrer" target="_blank" aria-label="Google Play">
        <picture class="d-flex d-lg-none">
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/google-play-2.webp" type="image/webp" />
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/google-play-2.png" type="image/png" />
          <img class="lazyload" width="140" height="40"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/google-play-2.png" alt="Google Play" />
        </picture>
        <picture class="d-none d-lg-flex">
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/google-play-2.webp" type="image/webp" />
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/google-play-2.png" type="image/png" />
          <img class="lazyload" width="160" height="45"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/google-play-2.png" alt="Google Play" />
        </picture>
      </a>
      <a class="btn btn-secondary bg-transparent text-decoration-none mt-3 mt-lg-0 ml-lg-3"
        href="https://itunes.apple.com/us/app/cubatel/id1300396049" rel="noreferrer" target="_blank"
        aria-label="App Store">
        <picture class="d-flex d-lg-none">
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/app-store-2.webp" type="image/webp" />
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/app-store-2.png" type="image/png" />
          <img class="lazyload" width="140" height="40"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/app-store-2.png" alt="App Store" />
        </picture>
        <picture class="d-none d-lg-flex">
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/app-store-2.webp" type="image/webp" />
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/app-store-2.png" type="image/png" />
          <img class="lazyload" width="160" height="45"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/app-store-2.png" alt="App Store" />
        </picture>
      </a>
    </div>
  </div>
  <div class="d-flex justify-content-center align-items-center">
    <picture class="d-none d-lg-flex">
      <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-box-182x320.webp" type="image/webp">
      <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-box-182x320.png" type="image/png">
      <img width="182" height="320" class="lazyload"
        data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-box-182x320.png"
        alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
    </picture>
    <picture class="d-flex d-lg-none">
      <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-box-240x374.webp" type="image/webp">
      <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-box-240x374.png" type="image/png">
      <img width="120" height="187" class="lazyload"
        data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-box-240x374.png"
        alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
    </picture>
    <h2 class="text-right d-flex d-lg-none Poppins ml-2">¡Hazte<br>Cubatel!</h2>

  </div>
</div>
<!-- END APPS SECTION # 1 -->

<!-- (OLD) PROMOTIONS SECTION -->
<!--<div id="promotions" class="d-flex flex-column align-items-center">
  <span *ngIf="!isAuthenticated()" class="Poppins stu-primary-color text-center">Promociones de bienvenida</span>
  <span *ngIf="isAuthenticated()" class="Poppins stu-primary-color text-center">Promociones disponibles</span>
  <div id="promotionsInfo" class="d-none d-lg-flex flex-column flex-lg-row justify-content-lg-center">
    <div class="px-lg-4 d-flex flex-column">
      <img *ngIf="!isAuthenticated()" width="70" class="lazyload"
        data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/stu-icon-percent-circle.svg"
        alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
      <p *ngIf="!isAuthenticated()" class="mb-0 mt-1 mb-4">Usa el código <span>DESCUENTO10</span> y envía tu primera
        recarga por solo <span>$19.59 USD</span>.</p>
      <p *ngIf="isAuthenticated() " class="mb-0 mt-1 mb-4"><span>Más OFERTAS</span><br>
        Elige enviar saldo, planes combinados o la promoción de recarga disponible a Cuba.</p>
      <button (click)="callToAction1()"
        class="btn btn-primary stu-bg-primary font-weight-bold OpenSans rounded-pill mt-lg-auto text-white">
        Enviar Recarga
      </button>
    </div>
    <div class="px-lg-4 d-flex flex-column">
      <img *ngIf="!isAuthenticated()" width="70" class="lazyload"
        data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/stu-icon-call-circle.svg"
        alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
      <p *ngIf="!isAuthenticated()" class="mb-0 mt-1 mb-4">¡Llama a <span>Cuba</span>! Instala nuestra app y habla
        <span>10 minutos por $1 USD</span>.
      </p>
      <p *ngIf="isAuthenticated() " class="mb-0 mt-1 mb-4"> <span>SMS GRATIS</span><br>
        Gana 3 SMS gratis por cada recarga completada y uno de cortesía al enviarla.</p>
      <a *ngIf="!isAuthenticated()" routerLink="/llamadas-a-cuba" routerLinkActive=""
        class="btn btn-primary stu-bg-primary font-weight-bold OpenSans rounded-pill mt-lg-auto">
        Llamar Ahora
      </a>
      <a *ngIf="isAuthenticated()" routerLink="/sms" routerLinkActive=""
        class="btn btn-primary stu-bg-primary font-weight-bold OpenSans rounded-pill mt-lg-auto">
        Enviar SMS
      </a>
    </div>
    <div class="px-lg-4 d-flex flex-column">
      <img *ngIf="!isAuthenticated()" width="70" class="lazyload"
        data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/stu-icon-send-circle.svg"
        alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
      <p *ngIf="!isAuthenticated()" class="mb-0 mt-1 mb-4">Regístrate en <span>Cubatel</span> y obtén <span>10 SMS
          gratis</span>. ¡Comunícate con tu gente!</p>
      <p *ngIf="isAuthenticated()" class="mb-0 mt-1 mb-4"><span>Llamadas a Cuba</span><br>
        Con calidad Premium, desde cualquier país y mdiante datos, Wi-Fi o número local.</p>
      <a *ngIf="!isAuthenticated()" routerLink="/sms" routerLinkActive=""
        class="btn btn-primary stu-bg-primary font-weight-bold OpenSans rounded-pill mt-lg-auto">
        Enviar SMS
      </a>
      <a *ngIf="isAuthenticated()" routerLink="/llamadas-a-cuba" routerLinkActive=""
        class="btn btn-primary stu-bg-primary font-weight-bold OpenSans rounded-pill mt-lg-auto text-white">
        Llamar Ahora
      </a>
    </div>
  </div>
  <div id="sliders-promotions" class="carousel slide d-block d-lg-none mt-5" data-touch="true" data-pause="false"
    data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#sliders-promotions" data-slide-to="0" class="active"></li>
      <li data-target="#sliders-promotions" data-slide-to="1"></li>
      <li data-target="#sliders-promotions" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item mb-2 active" data-interval="20000" data-index="0">
        <div class="d-flex flex-column align-items-center">
          <img width="89" height="89" src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/stu-icon-percent-circle.svg"
            alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
          <p *ngIf="!isAuthenticated()" class="text-center mt-4">Usa el código <span>DESCUENTO10</span> y envía tu
            primera recarga por solo <span>$19.59 USD</span>.</p>
          <p *ngIf="isAuthenticated()" class="text-center mt-4"><span>Más OFERTAS</span><br>
            Elige enviar saldo, planes combinados o la promoción de recarga disponible a Cuba.</p>
          <button (click)="callToAction1()"
            class="btn btn-primary stu-bg-primary font-weight-bold OpenSans rounded-pill mt-lg-auto text-white">
            Enviar Recarga
          </button>
        </div>
      </div>
      <div class="carousel-item mb-2" data-interval="20000" data-index="1">
        <div class="d-flex flex-column align-items-center">
          <img *ngIf="!isAuthenticated()" width="89" height="89"
            src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/stu-icon-call-circle.svg"
            alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
          <img *ngIf="isAuthenticated()" width="89" height="89"
            src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/stu-icon-send-circle.svg"
            alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
          <p *ngIf="!isAuthenticated()" class="text-center mt-4">¡Llama a <span>Cuba</span>! Instala nuestra app y habla
            <span>10 minutos por $1 USD</span>.
          </p>
          <p *ngIf="isAuthenticated()" class="text-center mt-4"><span>SMS GRATIS</span><br>
            Gana 3 SMS gratis por cada recarga completada y uno de cortesía al enviarla.</p>
          <a *ngIf="!isAuthenticated()" routerLink="/llamadas-a-cuba" routerLinkActive=""
            class="btn btn-primary stu-bg-primary font-weight-bold OpenSans rounded-pill mt-lg-auto">
            Llamar Ahora
          </a>
          <a *ngIf="isAuthenticated()" routerLink="/sms" routerLinkActive=""
            class="btn btn-primary stu-bg-primary font-weight-bold OpenSans rounded-pill mt-lg-auto">
            Enviar SMS
          </a>
        </div>
      </div>
      <div class="carousel-item mb-2" data-interval="20000" data-index="2">
        <div class="d-flex flex-column align-items-center">
          <img *ngIf="!isAuthenticated()" width="89" height="89"
            src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/stu-icon-send-circle.svg"
            alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
          <img *ngIf="isAuthenticated()" width="89" height="89"
            src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/stu-icon-call-circle.svg"
            alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
          <p *ngIf="!isAuthenticated()" class="text-center mt-4">Regístrate en <span>Cubatel</span> y obtén <span>10 SMS
              gratis</span>. ¡Comunícate con tu gente!</p>
          <p *ngIf="isAuthenticated()" class="text-center mt-4"> <span>Llamadas a Cuba</span><br>
            Con calidad Premium, desde cualquier país y mediante datos, Wi-Fi o número local.</p>
          <a *ngIf="!isAuthenticated()" routerLink="/sms" routerLinkActive=""
            class="btn btn-primary stu-bg-primary font-weight-bold OpenSans rounded-pill mt-md-auto">
            Enviar SMS
          </a>
          <a *ngIf="isAuthenticated()" routerLink="/llamadas-a-cuba" routerLinkActive=""
            class="btn btn-primary stu-bg-primary font-weight-bold OpenSans rounded-pill mt-md-auto">
            Llamar Ahora
          </a>
        </div>
      </div>
    </div>
    <a class="carousel-control-prev d-lg-none" href="#sliders-promotions" role="button" data-slide="prev">
      <span class="carousel-prev" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next d-lg-none" href="#sliders-promotions" role="button" data-slide="next">
      <span class="carousel-next" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>-->
<!-- END PROMOTIONS SECTION -->

<!-- (OLD) SERVICES SECTION -->
<!--<div id="services" class="d-flex flex-column">
  <span class="stu-default-color w-100 text-center mt-4 mb-md-4 Poppins">Nuestros Servicios</span>
  <div class="d-flex flex-column flex-md-row justify-content-center my-4">
    <div class="d-flex flex-column mx-md-3 mx-xl-4">
      <a class="btn btn-primary stu-bg-primary stu-primary-color d-none d-md-flex flex-column"
        [routerLink]="['/recargas']">
        <div class="align-items-center"><i class="stu-recharge-white"></i></div>
        <h2 class="text-center stu-default-color Poppins font-weight-bold">Recarga<br>
          Celular</h2>
      </a>
      <a class="btn btn-primary stu-primary-color d-flex d-md-none align-items-center justify-content-between bg-transparent mt-2 mb-4 recharge-link"
        [routerLink]="['/recargas']">
        <h2 class="Poppins font-weight-bold stu-primary-color mb-0">Recarga Celular </h2>
        <i class="stu-recharge-blue"></i>
      </a>
    </div>
    <div class="d-flex flex-column mx-md-3 mx-xl-4">
      <a class="btn btn-primary stu-bg-primary stu-primary-color d-none d-md-flex flex-column"
        [routerLink]="['/nauta']">
        <div class="align-items-center"><i class="stu-nauta-white"></i></div>
        <h2 class="text-center stu-default-color Poppins font-weight-bold">Recarga<br>
          Nauta</h2>
      </a>
      <a class="btn btn-primary stu-primary-color d-flex d-md-none align-items-center justify-content-between bg-transparent mb-4 nauta-link"
        [routerLink]="['/nauta']">
        <h2 class="Poppins font-weight-bold stu-primary-color mb-0">Recarga Nauta </h2>
        <i class="stu-nauta-blue"></i>
      </a>
    </div>
    <div class="d-flex flex-column mx-md-3 mx-xl-4">
      <a class="btn btn-primary stu-bg-primary stu-primary-color d-none d-md-flex flex-column"
        [routerLink]="['/mayorista']">
        <div class="align-items-center"><i class="stu-retail-white"></i></div>
        <h2 class="text-center stu-default-color Poppins font-weight-bold">Recargas<br>
          Mayoristas</h2>
      </a>
      <a class="btn btn-primary stu-primary-color d-flex d-md-none align-items-center justify-content-between bg-transparent mb-4 retail-link"
        [routerLink]="['/mayorista']">
        <h2 class="Poppins font-weight-bold stu-primary-color mb-0">Recargas Mayoristas </h2>
        <i class="stu-retail-blue"></i>
      </a>
    </div>
    <div class="d-flex flex-column mx-md-3 mx-xl-4">
      <a class="btn btn-primary stu-bg-primary stu-primary-color d-none d-md-flex flex-column"
        [routerLink]="['/llamadas-a-cuba']">
        <div class="align-items-center"><i class="stu-call-white"></i></div>
        <h2 class="text-center stu-default-color Poppins font-weight-bold">Llamadas<br>
          a Cuba</h2>
      </a>
      <a class="btn btn-primary stu-primary-color d-flex d-md-none align-items-center justify-content-between bg-transparent mb-4 call-link"
        [routerLink]="['/llamadas-a-cuba']">
        <h2 class="Poppins font-weight-bold stu-primary-color mb-0">Llamadas a Cuba </h2>
        <i class="stu-call-blue"></i>
      </a>
    </div>
    <div class="d-flex flex-column mx-md-3 mx-xl-4">
      <a class="btn btn-primary stu-bg-primary stu-primary-color d-none d-md-flex flex-column" [routerLink]="['/sms']">
        <div class="align-items-center"><i class="stu-sms-white"></i></div>
        <h2 class="text-center stu-default-color Poppins font-weight-bold">Envía<br>
          SMS</h2>
      </a>
      <a class="btn btn-primary stu-primary-color d-flex d-md-none align-items-center justify-content-between bg-transparent sms-link"
        [routerLink]="['/sms']">
        <h2 class="Poppins font-weight-bold stu-primary-color mb-0">Envía SMS </h2>
        <i class="stu-sms-blue"></i>
      </a>
    </div>
  </div>
</div>-->
<!-- END SERVICES SECTION -->

<!-- (OLD) CALL SECTION -->
<!--<div id="call" class="d-flex stu-bg-danger pt-xl-5">
  <div class="d-flex flex-column-reverse justify-content-center flex-lg-row py-4 pb-md-0 pt-xxl-5">
    <a routerLink="/llamadas-a-cuba"
      class="btn btn-primary stu-bg-primary text-white px-4 py-2 mt-lg-5 font-weight-bold OpenSans d-block d-lg-none w-75 align-self-center mb-4 rounded-pill">
      ¡Llama Ahora!
    </a>
    <div class="d-flex mt-4 position-relative mr-lg-2 pr-xl-5 mr-xl-5 justify-content-center">
      <picture class="d-flex d-md-none">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/heidy.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/heidy.png" type="image/png">
        <img width="249" height="256" class="lazyload" data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/heidy.png"
          alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
      </picture>
      <picture class="d-none d-md-flex">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/heidy.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/heidy.png" type="image/png">
        <img width="405" class="lazyload" data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/heidy.png"
          alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
      </picture>
      <picture id="cent-min" class="position-absolute d-none d-lg-flex">
        <source data-source="https://www.cubatel.com/images-ads/cdn/assets-v3/home/0.69.webp" type="image/webp" />
        <source data-source="https://www.cubatel.com/images-ads/cdn/assets-v3/home/0.69.png" type="image/png" />
        <img width="158" class="lazyload" data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/0.69.png" alt="$0.69/min">
      </picture>
      <picture id="cent-min" class="position-absolute d-flex d-lg-none">
        <source data-source="https://www.cubatel.com/images-ads/cdn/assets-v3/home/0.69.webp" type="image/webp" />
        <source data-source="https://www.cubatel.com/images-ads/cdn/assets-v3/home/0.69.png" type="image/png" />
        <img width="91" class="lazyload" data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/0.69.png" alt="$0.69/min">
      </picture>
    </div>
    <div class="d-flex flex-column mt-4 ml-lg-5 pl-xl-5 justify-content-center">
      <div id="call-info" class="d-flex justify-content-md-center">
        <div class="d-flex flex-column mr-4 mr-lg-5">
          <span class="Poppins text-white text-right font-weight-bold">Llama</span>
          <span class="Poppins text-white text-right">a Cuba<br>
            fácil, barato<br>
            con <span class="font-weight-bold">Cubatel</span>
          </span>
        </div>
        <div class="d-flex flex-column pl-4 pl-lg-5 pt-3">
          <img width="145" class="lazyload d-none d-lg-flex"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/white-stars.svg"
            alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
          <img width="74" height="12" class="lazyload d-flex d-lg-none"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/white-stars.svg"
            alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
          <span class="Poppins text-white mt-3 mt-lg-2">Calidad<br> Premium</span>
          <span class="Poppins text-white mt-2">Sonido HD</span>
        </div>
      </div>
      <a routerLink="/llamadas-a-cuba"
        class="btn btn-primary stu-bg-primary text-white px-4 py-3 mt-lg-5 font-weight-bold OpenSans d-none d-lg-block">
        ¡Llama Ahora!
      </a>
    </div>
  </div>
</div>-->
<!-- END CALL SECTION-->

<!-- REVIEWS SECTION-->
<div id="reviews" class="pt-5">
  <div class="d-flex flex-column align-items-md-center px-md-5 py-md-5">
    <h2 class="Poppins stu-primary-color text-center px-5">¿Por qué nos recomiendan?</h2>
    <div *ngIf="reviews.length > 0" id="sliders" class="carousel slide d-none d-lg-block" data-touch="true"
      data-pause="false" data-ride="carousel">
      <ol class="carousel-indicators">
        <li *ngFor="let item of reviewsDesktop; let indexOfElement = index" data-target="#sliders"
          [attr.data-slide-to]="indexOfElement" [ngClass]="{'active': indexOfElement == 0}"></li>
      </ol>
      <div class="carousel-inner">
        <div *ngFor="let list of reviewsDesktop; let index = index" [ngClass]="{'active': index == 0}"
          class="carousel-item " data-interval="20000" [attr.data-index]="index">
          <div class="d-flex flex-row align-items-start justify-content-center">
            <div *ngFor="let item of list" class="d-flex flex-column mx-xl-4 mx-xxl-4">
              <div
                class="comment-block justify-content-center d-flex flex-column align-items-center px-5 px-md-3 py-4 bg-white position-relative mx-md-4">
                <p class="text-center mb-2">{{item.comment}}</p>
                <p id="reviews-date" class="text-center mb-2">{{item.createdDate | dateAgo}}</p>
                <img width="97" height="16" src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/blue-stars.svg"
                  alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
              </div>
              <div class="d-flex flex-column align-items-center mt-5">
                <div class="avatar-preview stu-bg-primary d-flex justify-content-center align-items-center"
                  [style.background-color]="item.color">
                  <p class="mb-0 text-white">{{getFirst(item.authorName)?.letter}}</p>
                </div>
              </div>
              <div class="d-flex flex-column align-items-center mt-4"><span id="avatar-name"
                  class="Poppins font-weight-bold">{{item.authorName}}</span></div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="reviews.length > 0" id="sliders-mobile" class="carousel slide d-block d-lg-none mt-5" data-touch="true"
      data-pause="false" data-ride="carousel">
      <ol class="carousel-indicators">
        <li *ngFor="let item of reviews; let indexOfElement = index" data-target="#sliders-mobile"
          [attr.data-slide-to]="indexOfElement" [ngClass]="{'active': indexOfElement == 0}"></li>
      </ol>
      <div class="carousel-inner">
        <div *ngFor="let item of reviews; let index = index" [ngClass]="{'active': index == 0}" class="carousel-item "
          data-interval="20000" [attr.data-index]="index">
          <div class="d-flex flex-column align-items-center">
            <div
              class="comment-block justify-content-center d-flex flex-column align-items-center px-5 px-md-3 py-4 bg-white position-relative mx-md-4">
              <p class="text-center mb-2">{{item.comment}}</p>
              <p class="date text-center mb-2">{{item.createdDate | dateAgo}}</p>
              <img width="97" height="16" src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/blue-stars.svg"
                alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
            </div>
            <div class="d-flex flex-column align-items-center mt-5">
              <div class="avatar-preview stu-bg-primary d-flex justify-content-center align-items-center"
                [style.background-color]="item.color">
                <p class="mb-0 text-white">{{getFirst(item.authorName)?.letter}}</p>
              </div>
            </div>
            <div class="d-flex flex-column align-items-center mb-4 mb-md-0 mt-4"><span id="avatar-name"
                class="Poppins font-weight-bold">{{item.authorName}}</span></div>
          </div>
        </div>
      </div>
      <a class="carousel-control-prev d-lg-none" href="#sliders-mobile" role="button" data-slide="prev">
        <span class="carousel-prev" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next d-lg-none" href="#sliders-mobile" role="button" data-slide="next">
        <span class="carousel-next" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</div>
<!-- END REVIEW SECTION-->

<!-- APPS SECTION # 2 -->
<div id="appsSection2" class="pt-5 px-5 stu-bg-primary d-flex flex-column align-items-center">
  <div class="d-flex flex-column justify-content-center mt-md-4 mt-xl-5 pt-xl-5">
    <h2 class="h2 text-white Poppins font-weight-bold text-center text-md-left">Descarga nuestra App</h2>
    <div class="d-flex pt-md-5 justify-content-between flex-column flex-md-row">
      <div class="d-flex flex-column">
        <p class="text-white Poppins d-none d-md-flex">Hemos creado la<br />
          aplicación más sencilla <br />
          de servicios a Cuba.<br />
          LLamadas, envío de dinero, <br />
          recargas para Cuba y más.<br />
          Confía en nosotros <br />
          y hazte Cubatel.</p>
        <div class="d-flex mt-4 align-items-center flex-column flex-md-row">
          <picture class="d-none d-md-flex">
            <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/4.9.webp" type="image/webp">
            <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/4.9.png" type="image/png">
            <img width="149" height="149" class="lazyload" data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/4.9.png"
              alt="4.9">
          </picture>
          <picture class="d-flex d-md-none">
            <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/4.9.webp" type="image/webp">
            <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/4.9.png" type="image/png">
            <img width="112" height="112" class="lazyload" data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/4.9.png"
              alt="4.9">
          </picture>
          <p class="text-white ml-0 ml-md-4 Poppins mt-3 text-center text-md-left">Somos la App mejor<br>
            valorada de <br>
            Recargas para Cuba</p>
        </div>
      </div>
      <div class="position-relative d-flex align-items-end justify-content-center">
        <picture class="d-none d-md-flex">
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-app.webp" type="image/webp">
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-app.png" type="image/png">
          <img width="379" class="lazyload" data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-app.png"
            alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
        </picture>
        <picture class="d-flex d-md-none">
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-app.webp" type="image/webp">
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-app.png" type="image/png">
          <img width="268" height="323" class="lazyload"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/mobile-app.png"
            alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
        </picture>
        <div id="appsSection2-imgs" class="d-flex flex-column position-absolute mb-3">
          <a class="d-block d-md-none my-2 bg-transparent text-decoration-none p-0"
            href="https://play.google.com/store/apps/details?id=com.cubatel.android&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            rel="noreferrer" target="_blank" aria-label="Google Play">
            <img width="177" height="52" class="lazyload "
              data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/google-play-black.svg" alt="Google Play">
          </a>
          <a class="d-none d-md-block my-2 bg-transparent text-decoration-none p-0"
            href="https://play.google.com/store/apps/details?id=com.cubatel.android&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            rel="noreferrer" target="_blank" aria-label="Google Play">
            <img width="255" height="75" class="lazyload "
              data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/google-play-black.svg" alt="Google Play">
          </a>
          <a class="d-none d-md-block my-2 bg-transparent text-decoration-none p-0"
            href="https://itunes.apple.com/us/app/cubatel/id1300396049" rel="noreferrer" target="_blank"
            aria-label="App Store">
            <img width="255" height="75" class="lazyload d-none d-md-block"
              data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/app-store-black.svg" alt="App Store">
          </a>
          <a class="d-block d-md-none my-2 bg-transparent text-decoration-none p-0"
            href="https://itunes.apple.com/us/app/cubatel/id1300396049" rel="noreferrer" target="_blank"
            aria-label="App Store">
            <img width="177" height="52" class="lazyload "
              data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/app-store-black.svg" alt="App Store">
          </a>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- END APPS SECTION # 2 -->
<ng-template #snippet let-css>
  <div class="backdrop" [style]="css || 'border-radius: 25px'">
    <div class="snippet" data-title="dot-pulse">
      <div class="stage">
        <div class="dot-pulse"></div>
      </div>
    </div>
  </div>
</ng-template>
